import React, { FC, useContext, useEffect, useState, Fragment } from "react";
import { Page } from "../../components/Page";
import { Box, TextField, Paper, makeStyles, createStyles } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { nameof } from "../../utils/Helpers";
import { ApiPocketEloItem } from "../../models/elo/ApiPocketEloItem";
import { LocalService } from "../../services/LocalService";
import { MuiVirtualizedTable } from "../../components/ReactVirtualizedTable";
import { useLiveData } from "../../hooks/useLiveData";
import { EloService } from "../../services/EloService";
import { RouteList } from "../../Routes";
import { Redirect } from "react-router-dom";
import { AlertDialog } from "../../components/AlertDialog";
import { RegionButton } from "../../components/RegionButton";
import { LoadingStatus } from "../../components/LoadingStatus";
import { CharacterButton } from "../../components/CharacterButton";

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            height: 40,
        },
        btnImg: {
            maxWidth: 16,
        },
        paper: {
            width: "100%",
            height: "100%",
        },
    })
);

export const EloIndexPage: FC = () => {
    // these are styles for the page
    const classes = useStyles();

    // these are global variables which we get from the context
    const { game, setCurrentNavbarText, region, character, playerSearch, setCurrentPlayerSearch, setCurrentAllowInstallBtn } = useContext(GlobalContext);

    const statusKey = game ? LocalService.KeyEloStatus(game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return EloService.getGame(game.ID);
        }

        return Promise.resolve<ApiPocketEloItem[]>([]);
    };

    const getLocalData = () => {
        if (game) {
            return LocalService.getElo(game.ID);
        }

        return Promise.resolve<ApiPocketEloItem[]>([]);
    };

    // this is a custom hook that allows us to manage live / local data
    const { data, status } = useLiveData<Array<ApiPocketEloItem>>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    // these are property names which we use nameof<> for type safety
    const nameEloElo = nameof<ApiPocketEloItem>("Elo");
    const namePlayerRegionShort = nameof<ApiPocketEloItem>("PlayerRegionShort");
    const namePlayerName = nameof<ApiPocketEloItem>("PlayerName");

    // these are all the locally managed state items
    const [rows, setRows] = useState<ApiPocketEloItem[]>([]);
    const [redirect, setRedirect] = useState("");

    // this only needs to run once on page load
    useEffect(() => {
        setCurrentNavbarText("Elo");
        setCurrentAllowInstallBtn(true);
    }, [setCurrentNavbarText, setCurrentAllowInstallBtn]);

    // this needs to run every time the master data list changes
    // this performs the filter on the main data source
    useEffect(() => {
        let mounted = true;

        if (data) {
            let sorted = [...data];

            if (region) {
                sorted = sorted.filter((x) => x.PlayerRegionID === region.ID);
            }

            if (character) {
                sorted = sorted.filter((x) => x.CharacterID === character.ID);
            }

            if (playerSearch) {
                sorted = sorted.filter((x) => x.PlayerName.toLowerCase().indexOf(playerSearch.toLowerCase()) >= 0);
            }

            sorted = [...sorted].sort((a, b) => {
                return -(a.Elo - b.Elo);
            });

            if (mounted) {
                setRows(sorted);
            }
        }

        return () => {
            mounted = false;
        };
    }, [data, region, playerSearch, character]);

    // now to render the page
    return (
        <Page >
            {redirect && <Redirect to={redirect} />}
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {game ? (
                    <Fragment>
                        <AlertDialog />

                        <Box mb={3}>
                            <Box display="flex">
                                <Box mr={2}>
                                    <RegionButton />
                                </Box>

                                <Box mr={2}>
                                    <CharacterButton />
                                </Box>

                                <Box>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={playerSearch}
                                        onChange={(event) => {
                                            setCurrentPlayerSearch(event.target.value);
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        {rows.length > 0 && (
                            <Box maxWidth={500} height="100%">
                                <Paper className={classes.paper}>
                                    <MuiVirtualizedTable
                                        rowCount={rows.length}
                                        rowGetter={({ index }) => rows[index]}
                                        onRowClick={({ rowData }: { rowData: ApiPocketEloItem }) => {
                                            setRedirect(RouteList.Player(rowData.PlayerID.toString()));
                                        }}
                                        columns={[
                                            {
                                                width: 60,
                                                label: "Elo",
                                                dataKey: nameEloElo,
                                                numeric: true,
                                            },
                                            {
                                                width: 40,
                                                label: "",
                                                dataKey: `${namePlayerRegionShort}`,
                                                region: true,
                                            },
                                            {
                                                width: 160,
                                                label: "Player",
                                                dataKey: `${namePlayerName}`,
                                                link: true,
                                            },
                                            {
                                                width: 40,
                                                label: "",
                                                dataKey: "CharacterImageUrl",
                                            },
                                        ]}
                                    />
                                </Paper>
                            </Box>
                        )}

                        {status && rows.length === 0 && <LoadingStatus status={status} />}
                    </Fragment>
                ) : (
                    <Redirect to={RouteList.ProfileGame} />
                )}
            </Box>
        </Page>
    );
};
