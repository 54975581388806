import React, { FC } from "react";
import IconQLD from "../img/icons/qld.svg";
import IconNSW from "../img/icons/nsw.svg";
import IconVIC from "../img/icons/vic.svg";
import IconSA from "../img/icons/sa.svg";
import IconWA from "../img/icons/wa.svg";
import IconACT from "../img/icons/act.svg";
import IconNZ from "../img/icons/nz.svg";
import IconTAS from "../img/icons/tas.svg";
import IconNT from "../img/icons/nt.svg";
import IconINT from "../img/icons/world.svg";
import IconAUS from "../img/icons/australia.svg";

export type RegionShort = "QLD" | "NSW" | "VIC" | "SA" | "WA" | "ACT" | "NZ" | "TAS" | "NT" | "INT" | "AUS";
type RegionData = { icon: string };

type Props = {
    short: RegionShort;
};

export const regions: Record<RegionShort, RegionData> = {
    QLD: { icon: IconQLD },
    NSW: { icon: IconNSW },
    VIC: { icon: IconVIC },
    SA: { icon: IconSA },
    WA: { icon: IconWA },
    ACT: { icon: IconACT },
    NZ: { icon: IconNZ },
    TAS: { icon: IconTAS },
    NT: { icon: IconNT },
    INT: { icon: IconINT },
    AUS: { icon: IconAUS },
};

export const RegionIcon: FC<Props> = (props: Props) => {
    const regionData = regions[props.short];

    if (regionData) {
        return <img width="22" height="22" alt="" src={regionData.icon} className="region-icon" />;
    }

    return <div>{props.short}</div>;
};
