import React, { FC, useContext, useEffect, useState, Fragment } from "react";
import { Page } from "../../components/Page";
import { Box, TextField, Paper, makeStyles, createStyles } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ApiPocketResultItem } from "../../models/results/ApiPocketResultItem";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { ResultsService } from "../../services/ResultsService";
import { RouteList } from "../../Routes";
import { Redirect, Link as RouterLink } from "react-router-dom";
import { AlertDialog } from "../../components/AlertDialog";
import { RegionButton } from "../../components/RegionButton";
import { LoadingStatus } from "../../components/LoadingStatus";
import { CellMeasurerCache, AutoSizer, CellMeasurer, List } from "react-virtualized";
import { RegionIcon } from "../../components/RegionIcon";
import moment from "moment";

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            height: 40,
        },
        btnImg: {
            maxWidth: 16,
        },
        paper: {
            width: "100%",
            height: "100%",
        },
        item: {
            borderTop: "1px solid rgb(224, 224, 224)",
            "&:first-child": {
                borderTop: "none",
            },
        },
    })
);

export const ResultsIndexPage: FC = () => {
    // these are styles for the page
    const classes = useStyles();

    // these are global variables which we get from the context
    const { game, setCurrentNavbarText, region, character, resultSearch, setCurrentResultSearch, setCurrentAllowInstallBtn } = useContext(GlobalContext);

    const statusKey = game ? LocalService.KeyResultsStatus(game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return ResultsService.getGame(game.ID);
        }

        return Promise.resolve<ApiPocketResultItem[]>([]);
    };

    const getLocalData = () => {
        if (game) {
            return LocalService.getResults(game.ID);
        }

        return Promise.resolve<ApiPocketResultItem[]>([]);
    };

    // this is a custom hook that allows us to manage live / local data
    const { data, status } = useLiveData<Array<ApiPocketResultItem>>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    // these are all the locally managed state items
    const [rows, setRows] = useState<ApiPocketResultItem[]>([]);

    // this only needs to run once on page load
    useEffect(() => {
        setCurrentNavbarText("Results");
        setCurrentAllowInstallBtn(true);
    }, [setCurrentNavbarText, setCurrentAllowInstallBtn]);

    // this needs to run every time the master data list changes
    // this performs the filter on the main data source
    useEffect(() => {
        let mounted = true;

        if (data) {
            let sorted = [...data];

            if (region) {
                sorted = sorted.filter((x) => x.RegionShort === region.Short);
            }

            if (resultSearch) {
                sorted = sorted.filter((x) => x.Name.toLowerCase().indexOf(resultSearch.toLowerCase()) >= 0);
            }

            sorted = [...sorted].sort((a, b) => {
                return -(new Date(a.Date).getTime() - new Date(b.Date).getTime());
            });

            if (mounted) {
                setRows(sorted);
            }
        }

        return () => {
            mounted = false;
        };
    }, [data, region, resultSearch, character]);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    // now to render the page
    return (
        <Page >
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {game ? (
                    <Fragment>
                        <AlertDialog />

                        <Box mb={3}>
                            <Box display="flex">
                                <Box mr={2}>
                                    <RegionButton />
                                </Box>

                                <Box>
                                    <TextField
                                        label="Search"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={resultSearch}
                                        onChange={(event) => {
                                            setCurrentResultSearch(event.target.value);
                                        }}
                                    />
                                </Box> 
                            </Box>
                        </Box>

                        {rows.length > 0 && (
                            <Box maxWidth={500} height="100%">
                                <Paper className={classes.paper}>
                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <List
                                                height={height}
                                                width={width}
                                                rowCount={rows.length}
                                                rowHeight={cache.rowHeight}
                                                rowRenderer={({ key, index, style, parent }) => {
                                                    const obj = rows[index];

                                                    return (
                                                        <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
                                                            <Box className={classes.item} style={style}>
                                                                <Box p={1} display="flex" flexDirection="row" width="100%">
                                                                    <Box flexShrink={0} width={40} display="flex" alignItems="center">
                                                                        <RegionIcon short={obj.RegionShort} />
                                                                    </Box>
                                                                    <Box
                                                                        flexGrow={1}
                                                                        display="flex"
                                                                        alignItems="flex-start"
                                                                        flexDirection="column"
                                                                        justifyContent="center"
                                                                        pr={1}
                                                                    >
                                                                        <Box
                                                                            display="flex"
                                                                            flexDirection="row"
                                                                            justifyContent="space-between"
                                                                            width="100%"
                                                                            alignItems="center"
                                                                        >
                                                                            <RouterLink to={RouteList.ResultPlacings(obj.ID.toString())}>

                                                                            {obj.Name}
                                                                                </RouterLink>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box width={70} flexShrink={0} display="flex" justifyContent="flex-end" alignItems="center">
                                                                        <small>{moment(obj.Date).format("DD/MM/YYYY")}</small>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </CellMeasurer>
                                                    );
                                                }}
                                            />
                                        )}
                                    </AutoSizer>
                                </Paper>
                            </Box>
                        )}

                        {status && rows.length === 0 && <LoadingStatus status={status} />}
                    </Fragment>
                ) : (
                    <Redirect to={RouteList.ProfileGame} />
                )}
            </Box>
        </Page>
    );
};
