export interface DataStorageState {
    Id: string;
    LastUpdated?: Date;
}

export const DefaultDataStorageState = (id: string): DataStorageState => {
    return {
        Id: id,
    };
};

export const DataStorageStateKeys = {
    elo: (gameId: number) => `Elo${gameId}`,
};
