import React, { FC, useEffect, useContext, useState, ChangeEvent } from "react";
import { Page } from "../../components/Page";
import { GlobalContext } from "../../contexts/GlobalContext";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { LocalService } from "../../services/LocalService";
import { DataUpdateFrequencyData, DataUpdateFrequency, DataUpdateFrequencyType } from "../../hooks/useOfflineData";

export const ProfileSettingsPage: FC = () => {
    const { setCurrentNavbarText, setCurrentAllowInstallBtn } = useContext(GlobalContext);

    const radioValues: DataUpdateFrequencyData[] = [];

    for (const key in DataUpdateFrequency) {
        const type = key as DataUpdateFrequencyType;

        if (type) {
            const value = DataUpdateFrequency[type];

            radioValues.push(value);
        }
    }

    const initialUpdateFrequency = LocalService.getDataUpdateFrequency();

    const [value, setValue] = useState(initialUpdateFrequency.key);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);

        const key = e.target.value as DataUpdateFrequencyType;

        if (key) {
            const value = radioValues.find(x => x.key === key);

            if (value) {
                LocalService.setDataUpdateFrequency(value);
            }
        }
    };

    useEffect(() => {
        setCurrentNavbarText("Settings");
        setCurrentAllowInstallBtn(true);
    }, [setCurrentNavbarText, setCurrentAllowInstallBtn]);

    return (
        <Page>
            <Box mb={2}>
                <FormControl component="fieldset">
                    <FormLabel component="legend">Data update frequency</FormLabel>
                    <RadioGroup aria-label="DataUpdateFrequency" name="DataUpdateFrequency" value={value} onChange={handleChange}>
                        {radioValues.map((x) => {
                            return <FormControlLabel key={x.key} value={x.key} control={<Radio />} label={x.key} />;
                        })}
                    </RadioGroup>
                </FormControl>
            </Box>

            <Alert color="info">Lowering the data update frequency can save data on mobile</Alert>
        </Page>
    );
};
