import React, { FC, Fragment, useState, useContext, useEffect } from "react";
import { makeStyles, createStyles, Button, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import { ApiPocketCharacter } from "../models/characters/ApiPocketCharacter";
import { GlobalContext } from "../contexts/GlobalContext";
import IconSmash from "../img/icons/smash.svg";

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            height: 40,
        },
        btnImg: {
            maxWidth: 16,
        },
        paper: {
            width: "100%",
            height: "100%",
        },
    })
);



export const CharacterButton: FC = () => {
    const classes = useStyles();

    const {
        game,
        characters,
        character,
        setCurrentCharacter,
    } = useContext(GlobalContext);

    const [currentCharacters, setCurrentCharacters] = useState<ApiPocketCharacter[]>([]);
    const [characterEl, setCharacterEl] = useState<null | HTMLElement>(null);

    // these are methods relating to various button clicks
    const handleCharacterMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCharacterEl(event.currentTarget);
    };

    const handleCharacterMenuClosed = () => {
        setCharacterEl(null);
    };

    const handleCharacterMenuItemClick = (c: ApiPocketCharacter | null) => {
        setCurrentCharacter(c);
        handleCharacterMenuClosed();
    };

    // this runs every time the game is changed
    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (game) {
                // update characters for page
                const gameCharacters = [...characters.filter((x) => x.GameID === game.ID)];
                setCurrentCharacters(gameCharacters);

                // deselect character if not on the page
                if (character && gameCharacters.find((x) => x.ID === character.ID) == null) {
                    setCurrentCharacter(null);
                }
            }
        }

        return () => {
            mounted = false;
        };
    }, [game, characters, setCurrentCharacter, character]);

    return (
        <Fragment>
            <Button className={classes.btn} variant="outlined" onClick={handleCharacterMenuClick}>
                {character ? <img alt="" src={character.ImageUrl} /> : <img alt="" className={classes.btnImg} src={IconSmash} />}
            </Button>

            <Menu anchorEl={characterEl} open={Boolean(characterEl)} keepMounted onClose={handleCharacterMenuClosed}>
                <MenuItem onClick={() => handleCharacterMenuItemClick(null)}>
                    <ListItemIcon>
                        <img alt="" className={classes.btnImg} src={IconSmash} />
                    </ListItemIcon>
                    All characters
                </MenuItem>
                {currentCharacters.map((c) => (
                    <MenuItem onClick={() => handleCharacterMenuItemClick(c)} key={c.ID}>
                        <ListItemIcon>
                            <img alt="" src={c.ImageUrl} />
                        </ListItemIcon>
                        {c.Name}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};
