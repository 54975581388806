import React, { FC, useContext, useState, Fragment } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChessKing, faQuestionCircle, faCog, IconDefinition, faTrophy } from "@fortawesome/pro-solid-svg-icons";
import { ThemeValues } from "../../utils/ThemeValues";
import { makeStyles, Theme, createStyles, useTheme, Divider, List, ListItem, Hidden, Drawer, SwipeableDrawer } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { RouteList } from "../../Routes";
import { LocalService } from "../../services/LocalService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            boxShadow: "6px 0 6px rgba(0,0,0,.1)",
            fontSize: 14,
            [theme.breakpoints.up("sm")]: {
                width: ThemeValues.drawerWidth,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: ThemeValues.drawerWidth,
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(2),
            zIndex: 1000,
            [theme.breakpoints.up("sm")]: {
                top: 64,
            },
        },
        navLink: {
            color: "#202124",
            borderTopRightRadius: 25,
            borderBottomRightRadius: 25,
            paddingTop: 12,
            paddingBottom: 12,
            "&:hover,&:focus,&:active": {
                backgroundColor: "#e8eaed",
            },
        },
        navLinkIcon: {
            width: 30,
            textAlign: "left",
        },
    })
);

type NavLinkProps = {
    to: string;
    text: string;
    icon: IconDefinition;
};

export const SideNav: FC = () => {
    const classes = useStyles();
    const theme = useTheme();

    const { setCurrentSideNavMobileOpen, sideNavMobileOpen, setCurrentIsAuthenticated, isAuthenticated } = useContext(GlobalContext);

    const [redirect, setRedirect] = useState("");

    const logout = () => {
        LocalService.removeToken();
        setCurrentIsAuthenticated(false);
        setRedirect(RouteList.AccountLogin);
    };

    const handleDrawerToggle = () => {
        setCurrentSideNavMobileOpen(!sideNavMobileOpen);
    };

    const navLink: FC<NavLinkProps> = (props: NavLinkProps) => {
        return (
            <ListItem className={classes.navLink} activeClassName="nav-link--active" component={NavLink} to={props.to}>
                <div className={classes.navLinkIcon}>
                    <FontAwesomeIcon icon={props.icon} />
                </div>
                {props.text}
            </ListItem>
        );
    };

    const drawer = (
        <div>
            {isAuthenticated && (
                <Fragment>
                    <List component="div">
                        {navLink({ to: RouteList.Elo, icon: faChessKing, text: "Elo / Players" })}
                        {navLink({ to: RouteList.Results, icon: faTrophy, text: "Results" })}
                    </List>
                    <Divider />
                    <List component="div">
                        {navLink({ to: RouteList.ProfileSettings, icon: faCog, text: "Settings" })}
                        {navLink({ to: RouteList.About, icon: faQuestionCircle, text: "About" })}

                        {/* {isAuthenticated && (
                            <ListItem>
                                <Button size="small" variant="contained" onClick={logout}>
                                    Logout
                                </Button>
                            </ListItem>
                        )} */}
                    </List>
                </Fragment>
            )}
        </div>
    );

    return (
        <Fragment>
            {redirect && <Redirect to={redirect} />}

            <nav className={classes.drawer}>
                <Hidden smUp implementation="css">
                    <SwipeableDrawer
                        container={window.document.body}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={sideNavMobileOpen}
                        onOpen={handleDrawerToggle}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </SwipeableDrawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
        </Fragment>
    );
};
