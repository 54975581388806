import React, { FC, useContext, useEffect, Fragment } from "react";
import { Page } from "../../components/Page";
import {
    makeStyles,
    Theme,
    createStyles,
    Box,
    Card,
    CardContent,
    Typography,
    Chip,
    IconButton,
    Divider,
} from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { ApiPocketPlayerStats } from "../../models/players/ApiPocketPlayerStats";
import { useLiveData } from "../../hooks/useLiveData";
import { PlayersService } from "../../services/PlayersService";
import TwitterIcon from "@material-ui/icons/Twitter";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlayerHeader } from "../../components/PlayerHeader";
import { PlayerNavigation } from "../../components/PlayerNavigation";
import { LoadingStatus } from "../../components/LoadingStatus";
import { PlayerBreadcrumb } from "../../components/breadcrumbs/PlayerBreadcrumb";

type Props = {
    id: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        cardStat: {
            width: 100,
            textAlign: "center",
            marginBottom: theme.spacing(2),
        },
        cardStatInner: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
        cardRanking: {
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        chip: {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
    })
);

export const PlayerStatsPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation } = useContext(GlobalContext);

    const statusKey = game ? LocalService.KeyPlayerStats(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return PlayersService.getPlayer(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerStats>({} as ApiPocketPlayerStats);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getPlayer(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerStats>({} as ApiPocketPlayerStats);
    };

    const { data, status } = useLiveData<ApiPocketPlayerStats | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    useEffect(() => {
        setCurrentNavbarText("Player");

        if (data) {
            setCurrentBottomNavigation(<PlayerNavigation playerId={data.ID} value={0} />);
        }
    }, [setCurrentNavbarText, setCurrentBottomNavigation, data]);

    const statCard = (top: string, bottom: string) => (
        <Card className={classes.cardStat}>
            <CardContent className={classes.cardStatInner}>
                <Typography variant="h5" component="div">
                    <strong>{top}</strong>
                </Typography>
                <Typography variant="body2" component="div" noWrap>
                    {bottom}
                </Typography>
            </CardContent>
            <div></div>
        </Card>
    );

    return (
        <Page >
            <Box width="100%" maxWidth={500} height="100%">
                {data != null ? (
                    <Fragment>
                        <PlayerBreadcrumb text="Stats" />

                        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                <PlayerHeader name={data.PlayerName} />
                            </Box>
                            <Box pl={2}>
                                {data.TwitterUrl ? (
                                    <IconButton component="a" href={data.TwitterUrl} target="_blank" color="primary" size="small">
                                        <TwitterIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton component="button" disabled size="small">
                                        <TwitterIcon />
                                    </IconButton>
                                )}
                                {data.TwitchUrl ? (
                                    <IconButton component="a" href={data.TwitchUrl} target="_blank" color="primary" size="small">
                                        <FontAwesomeIcon icon={faTwitch} />
                                    </IconButton>
                                ) : (
                                    <IconButton component="button" disabled size="small">
                                        <FontAwesomeIcon icon={faTwitch} />
                                    </IconButton>
                                )}
                            </Box>
                        </Box>

                        {data.CharacterUsage != null && (
                            <Box mb={1}>
                                {data.CharacterUsage.map((c) => {
                                    return <Chip key={c.Name} className={classes.chip} label={c.Name} icon={<img alt="" src={c.ImageUrl} />} />;
                                })}
                            </Box>
                        )}

                        <Box display="flex" justifyContent="space-between" width="100%">
                            {statCard(data.Elo.toString(), "Elo")}
                            {statCard(data.WinPercentage.toString() + "%", "Win rate")}
                            {statCard(data.MatchCount.toString(), "Matches")}
                        </Box>

                        {data.Rankings && data.Rankings.length > 0 && (
                            <Box mb={2}>
                                <Card>
                                    <CardContent className={classes.cardRanking}>
                                        {data.Rankings.map((r, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <Box display="flex" alignItems="center" py={1}>
                                                        <Box mr={2} width={28} flexGrow={0} flexShrink={0}>
                                                            <strong>{r.Place}</strong>
                                                        </Box>
                                                        <div>{r.Ranking}</div>
                                                    </Box>
                                                    {index !== data.Rankings.length - 1 && <Divider />}
                                                </Fragment>
                                            );
                                        })}
                                    </CardContent>
                                    <div></div>
                                </Card>
                            </Box>
                        )}
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};
