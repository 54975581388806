import React, { FC, useContext, useEffect, Fragment, createRef, useState, useCallback } from "react";
import Alert from "@material-ui/lab/Alert";
import { Page } from "../../components/Page";
import { makeStyles, createStyles, Box, Paper, Theme, Typography } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { ResultsService } from "../../services/ResultsService";
import { ResultHeader } from "../../components/ResultHeader";
import { ResultNavigation } from "../../components/ResultNavigation";
import { LoadingStatus } from "../../components/LoadingStatus";
import { ApiPocketResultMatches, ApiPocketResultMatchEventMatch, ApiPocketResultMatchEvent } from "../../models/results/ApiPocketResultMatches";
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from "react-virtualized";
import { RegionIcon } from "../../components/RegionIcon";
import { ResultBreadcrumb } from "../../components/breadcrumbs/ResultBreadcrumb";
import { Link as RouterLink } from "react-router-dom";
import { RouteList } from "../../Routes";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

type Props = {
    id: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: "100%",
            height: "100%",
            display: "flex",
        },
        event: {},
        matchResult: {
            width: "50%",
            textAlign: "center",
        },
        badge: {
            marginLeft: theme.spacing(1),
            color: theme.palette.success.main,
        },
        badgeLoss: {
            marginLeft: theme.spacing(1),
            color: theme.palette.error.main,
        },
        match: {
            borderTop: "1px solid rgb(224, 224, 224)",
            "&:last-child": {
                borderBottom: "1px solid rgb(224, 224, 224)",
            },
        },
        menuItem: {
            whiteSpace: "normal",
        },
        select: {
            maxWidth: "100%",
        },
    })
);

export const ResultMatchesPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation } = useContext(GlobalContext);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const listRef = createRef<List>();

    const [selectedEventId, setSelectedEventId] = useState<number | null>();
    const [rows, setRows] = useState<ApiPocketResultMatchEvent[]>([]);

    const statusKey = game ? LocalService.KeyResultMatches(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return ResultsService.getMatches(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketResultMatches>({} as ApiPocketResultMatches);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getResultMatches(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketResultMatches>({} as ApiPocketResultMatches);
    };

    const { data, status } = useLiveData<ApiPocketResultMatches | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    const handleEventChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedEventId(event.target.value as number);
      };

    const selectFirstEvent = useCallback(() => {
            if (selectedEventId == null) {
                if (data && data.Events && data.Events.length > 0) {
                    setSelectedEventId(data.Events[0].EventID);
                }
            }
      }, [data, setSelectedEventId, selectedEventId]);

    useEffect(selectFirstEvent, [data]);

    useEffect(() => {
        if (data) {
            let newRows = data.Events;

            if (selectedEventId) {
                newRows = [...data.Events.filter((e) => e.EventID === selectedEventId)];
            }

            setRows(newRows);
        }
    }, [selectedEventId, data]);

    useEffect(() => {
        setCurrentNavbarText("Result");

        if (data) {
            setCurrentBottomNavigation(<ResultNavigation ResultId={data.TourneyID} value={1} />);
        }
    }, [setCurrentNavbarText, setCurrentBottomNavigation, data]);

    return (
        <Page >
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {data ? (
                    <Fragment>
                        <ResultBreadcrumb text="Matches" />

                        <Box alignItems="center">
                            <ResultHeader name={data.ResultName} />
                        </Box>

                        {selectedEventId && data.Events.length > 0 && (
                            <Box mb={2}>
                                <FormControl className={classes.select}>
                                    <Select
                                        value={selectedEventId}
                                        onChange={handleEventChange}
                                        displayEmpty
                                        className={classes.select}
                                    >
                                        {data.Events.map((e) => (
                                            <MenuItem className={classes.menuItem} key={e.EventID} value={e.EventID} >
                                                {e.EventName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        <Box maxWidth={500} height="100%">
                            {data.Events.length > 0 ? (
                                <Paper className={classes.paper}>
                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <List
                                                ref={listRef}
                                                height={height}
                                                width={width}
                                                deferredMeasurementCache={cache}
                                                rowCount={rows.length}
                                                rowHeight={cache.rowHeight}
                                                rowRenderer={({ key, index, style, parent }) => {
                                                    const obj = rows[index];

                                                    return (
                                                        <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
                                                            <Box style={style}>
                                                                {obj.Matches.map((m) => {
                                                                    return <ResultEventMatchMatch match={m} key={m.MatchID} />;
                                                                })}
                                                            </Box>
                                                        </CellMeasurer>
                                                    );
                                                }}
                                            />
                                        )}
                                    </AutoSizer>
                                </Paper>
                            ) : (
                                <Alert color="warning">This Result has no matches to display</Alert>
                            )}
                        </Box>
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};

type ResultEventMatchMatchProps = {
    match: ApiPocketResultMatchEventMatch;
};

const ResultEventMatchMatch: FC<ResultEventMatchMatchProps> = (props: ResultEventMatchMatchProps) => {
    const classes = useStyles();

    const { match } = props;

    return (
        <Box display="flex" flexDirection="row" p={1} className={classes.match}>
            <Box width="100%">
                <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                    <Box display="flex" alignItems="center" width="100%">
                        <Box mr={2}>{match.WinnerRegionShort ? <RegionIcon short={match.WinnerRegionShort} /> : <RegionIcon short="AUS" />}</Box>
                        <Box>
                            {match.WinnerID ? (
                                <Typography noWrap component={RouterLink} to={RouteList.Player(match.WinnerID.toString())}>
                                    {match.WinnerName}
                                </Typography>
                            ) : (
                                <Typography noWrap>{match.WinnerName}</Typography>
                            )}
                        </Box>
                        <Box>
                            {match.EloMovement && match.EloMovement !== 0 ? (
                                <Typography variant="caption" component="small" className={classes.badge}>
                                    +{match.EloMovement}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                    <Box ml="auto" pl={2} display="flex" flexDirection="row">
                        {match.WinnerCharacters.map((c) => (
                            <Box key={c.ID} ml={1}>
                                <img key={c.ID} alt="" src={c.ImageUrl} />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                    <Box display="flex" alignItems="center">
                        <Box mr={2}>{match.LoserRegionShort ? <RegionIcon short={match.LoserRegionShort} /> : <RegionIcon short="AUS" />}</Box>
                        <Box>
                            {match.LoserID ? (
                                <Typography noWrap component={RouterLink} to={RouteList.Player(match.LoserID.toString())}>
                                    {match.LoserName}
                                </Typography>
                            ) : (
                                <Typography noWrap>{match.LoserName}</Typography>
                            )}
                        </Box>
                        <Box>
                            {match.EloMovement && match.EloMovement !== 0 ? (
                                <Typography variant="caption" component="small" className={classes.badgeLoss}>
                                    -{match.EloMovement}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                    <Box ml="auto" pl={2} display="flex" flexDirection="row">
                        {match.LoserCharacters.map((c) => (
                            <Box key={c.ID} ml={1}>
                                <img key={c.ID} alt="" src={c.ImageUrl} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
