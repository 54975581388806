import { IApiPostResult } from "../models/api/IApiPostResult";
import { TokenKey } from "../contexts/GlobalContext";
import { SmashDatabase } from "../db/SmashDatabase";

export abstract class BaseApiService {
    private static apiUrl = process.env.REACT_APP_API_URL;
    
    static db = new SmashDatabase();

    protected static getApiUrl(url: string) {
        return `${this.apiUrl}${url}`;
    }

    protected static headers() {
        const headers = new Headers();

        const token = "LQUW2R222SIH8Y322GM4";
            // localStorage.getItem(TokenKey);

        if (token) {
            headers.append("X-ApiKey", token);
        }

        return headers;
    }

    static get<T>(url: string): Promise<T> {
        return fetch(this.getApiUrl(url), {
            headers: this.headers(),
            method: "GET",
        }).then((x) => x.json() as Promise<T>);
    }

    static objectToFormData = (obj: any, rootName: string, ignoreList: boolean) => {
        var formData = new FormData();

        const appendFormData = (data: any, root: string) => {
            if (!ignore(root)) {
                root = root || "";
                if (data instanceof File) {
                    formData.append(root, data);
                } else if (Array.isArray(data)) {
                    for (var i = 0; i < data.length; i++) {
                        appendFormData(data[i], root + "[" + i + "]");
                    }
                } else if (typeof data === "object" && data) {
                    for (var key in data) {
                        if (data.hasOwnProperty(key)) {
                            if (root === "") {
                                appendFormData(data[key], key);
                            } else {
                                appendFormData(data[key], root + "." + key);
                            }
                        }
                    }
                } else {
                    if (data !== null && typeof data !== "undefined") {
                        formData.append(root, data);
                    }
                }
            }
        };

        function ignore(root: string) {
            return (
                Array.isArray(ignoreList) &&
                ignoreList.some(function (x) {
                    return x === root;
                })
            );
        }

        appendFormData(obj, rootName);

        return formData;
    };

    private static submitForm<T>(url: string, data: any, method: string): Promise<IApiPostResult<T>> {
        const form = this.objectToFormData(data, "", false);

        return fetch(this.getApiUrl(url), {
            headers: this.headers(),
            method: method,
            body: form,
        }).then((x) => x.json() as Promise<IApiPostResult<T>>);
    }

    static post<T>(url: string, data: any): Promise<IApiPostResult<T>> {
        return this.submitForm<T>(url, data, "POST");
    }

    static put<T>(url: string, data: any): Promise<IApiPostResult<T>> {
        return this.submitForm<T>(url, data, "PUT");
    }

    static delete<T>(url: string): Promise<IApiPostResult<T>> {
        return this.submitForm<T>(url, null, "DELETE");
    }
}
