import { BaseApiService } from "./BaseApiService";
import { ApiPocketPlayerStats } from "../models/players/ApiPocketPlayerStats";
import { ApiPocketPlayerWinRate } from "../models/players/ApiPlayerWinRateIndexItem";
import { ApiPocketPlayerResults } from "../models/players/ApiPocketPlayerResults";
import { ApiPocketPlayerVideos } from "../models/players/ApiPocketPlayerVideos";
import { ApiPocketPlayerMatches } from "../models/players/ApiPocketPlayerMatches";

export class PlayersService extends BaseApiService {
    static getPlayer(playerId: number, gameId: number): Promise<ApiPocketPlayerStats> {
        return this.get<ApiPocketPlayerStats>(`/pocket/player/${playerId}/${gameId}`).then((x) => {
            this.db.players.put(x);
            return x;
        });
    }

    static getWinRates(playerId: number, gameId: number): Promise<ApiPocketPlayerWinRate> {
        return this.get<ApiPocketPlayerWinRate>(`/pocket/player/winrates/${playerId}/${gameId}`).then((x) => {
            this.db.playerWinRates.put(x);
            return x;
        });
    }

    static getResults(playerId: number, gameId: number): Promise<ApiPocketPlayerResults> {
        return this.get<ApiPocketPlayerResults>(`/pocket/player/results/${playerId}/${gameId}`).then((x) => {
            this.db.playerResults.put(x);
            return x;
        })
    }

    static getVideos(playerId: number, gameId: number): Promise<ApiPocketPlayerVideos> {
        return this.get<ApiPocketPlayerVideos>(`/pocket/player/videos/${playerId}/${gameId}`).then((x) => {
            this.db.playerVideos.put(x);
            return x;
        })
    }

    static getMatches(playerId: number, gameId: number): Promise<ApiPocketPlayerMatches> {
        return this.get<ApiPocketPlayerMatches>(`/pocket/player/matches/${playerId}/${gameId}`).then((x) => {
            this.db.playerMatches.put(x);
            return x;
        })
    }
}
