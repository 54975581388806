import React, { FC, useContext } from "react";
import { Page } from "../../components/Page";
import { GlobalContext } from "../../contexts/GlobalContext";
import { RouteList } from "../../Routes";
import { Redirect } from "react-router-dom";
import { makeStyles, createStyles, ButtonGroup, Button, Paper, Theme, Box, Typography, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: "100%",
            display: "flex",
        },
        heading: {
            marginBottom: theme.spacing(2),
        },
        paper: {
            padding: theme.spacing(3),
        },
        btn: {
            textTransform: "none",
            justifyContent: "start",
            textAlign: "left",
        },
    })
);

export const ProfileGamePage: FC = () => {
    const classes = useStyles();

    const { game, games, setCurrentGame } = useContext(GlobalContext);

    return (
        <Page showFrame={false}>
            {game && <Redirect to={RouteList.Elo} />}

            <div className={classes.container}>
                <Box maxWidth={450} m="auto">
                    {games == null ? (
                        <CircularProgress />
                    ) : (
                        <Paper className={classes.paper}>
                            <Typography variant="h6" component="h1" className={classes.heading}>
                                Select game
                            </Typography>
                            <ButtonGroup orientation="vertical">
                                {games.map((g) => {
                                    return (
                                        <Button
                                            onClick={() => setCurrentGame(g)}
                                            className={classes.btn}
                                            startIcon={<img alt="" src={g.ImageUrl} />}
                                            key={g.ID}
                                        >
                                            {g.Name}
                                        </Button>
                                    );
                                })}
                            </ButtonGroup>
                        </Paper>
                    )}
                </Box>
            </div>
        </Page>
    );
};
