import { BaseApiService } from "./BaseApiService";
import { ApiPocketEloItem } from "../models/elo/ApiPocketEloItem";

export class EloService extends BaseApiService {
    static getGame(id: number): Promise<Array<ApiPocketEloItem>> {
        return this.get<Array<ApiPocketEloItem>>(`/pocket/elo/${id}`).then((x) => {
            this.db.elos.bulkPut(x);
            return x;
        });
    }
}
