import React, { FC, useContext, Fragment } from "react";
import { Page } from "../../components/Page";
import { useForm, Controller } from "react-hook-form";
import { AccountLoginModel, AccountLoginModelValidationSchema } from "../../models/account/AccountLoginModel";
import { TextField, Button, Box, CircularProgress, Paper, createStyles, makeStyles, Theme, Link } from "@material-ui/core";
import { useFormState } from "../../hooks/useFormState";
import { AccountService } from "../../services/AccountService";
import { nameof } from "../../utils/Helpers";
import { RouteList } from "../../Routes";
import { Redirect } from "react-router-dom";
import { GlobalContext, TokenKey } from "../../contexts/GlobalContext";
import { H1 } from "../../components/H1";
import { Link as RouteLink } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import ImgLogo from "../../img/logo-large.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            padding: theme.spacing(5),
            textAlign: "center",
        },
        container: {
            height: "100%",
            display: "flex",
            alignItems: "center",
        },
    })
);

export const AccountLoginPage: FC = () => {
    const classes = useStyles();

    const fieldUsername = nameof<AccountLoginModel>("UserName");
    const fieldPassword = nameof<AccountLoginModel>("Password");

    const { success, loading, setLoading, failure, handleFormResponse, confirmServerError } = useFormState();
    const { handleSubmit, errors, control, setError } = useForm<AccountLoginModel>({ validationSchema: AccountLoginModelValidationSchema });
    const { setCurrentIsAuthenticated } = useContext(GlobalContext);

    const reload = () => {
        window.location.reload(true);
    };

    const onSubmit = handleSubmit((model) => {
        setLoading(true);

        AccountService.login(model)
            .then((x) => {
                if (x.Success) {
                    localStorage.setItem(TokenKey, x.Data.Key);
                    setCurrentIsAuthenticated(true);
                } else {
                    handleFormResponse(x.Success, x.Errors, setError);
                }
            })
            .catch(confirmServerError);
    });

    return (
        <Page showFrame={false}>
            <Redirect to={RouteList.Elo} />

            <div className={classes.container}>
                <Box maxWidth={450} mx="auto">
                    {loading ? (
                        <CircularProgress />
                    ) : failure ? (
                        <Fragment>
                            <Box textAlign="center" marginBottom={2}>
                                <Alert severity="error">Could not connect to server to login</Alert>
                            </Box>
                            <Box textAlign="center">
                                <Button type="submit" variant="contained" color="primary" onClick={reload}>
                                    Reload
                                </Button>
                            </Box>
                        </Fragment>
                    ) : success ? (
                        <Redirect to={RouteList.Home} />
                    ) : (
                        <Fragment>
                            <Paper elevation={0} variant="outlined" className={classes.card}>
                                <Box>
                                    <img alt="Ausmash" src={ImgLogo} />
                                </Box>

                                <H1
                                    text="Ausmash Pocket"
                                    subtext={
                                        <Fragment>
                                            A <strong>fast</strong> way to access Ausmash data
                                        </Fragment>
                                    }
                                />

                                <form onSubmit={onSubmit}>
                                    <Box mb={3}>
                                        <Box mb={2}>
                                            <Controller
                                                as={
                                                    <TextField
                                                        label="Ausmash username"
                                                        autoFocus={true}
                                                        variant="outlined"
                                                        fullWidth={true}
                                                        helperText={errors && errors.UserName != null ? errors.UserName.message : ""}
                                                        error={errors && errors.UserName != null}
                                                    />
                                                }
                                                name={fieldUsername}
                                                defaultValue=""
                                                control={control}
                                            />
                                        </Box>
                                        <Box>
                                            <Controller
                                                as={
                                                    <TextField
                                                        label={fieldPassword}
                                                        type="password"
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        helperText={errors && errors.Password != null ? errors.Password.message : ""}
                                                        error={errors && errors.Password != null}
                                                    />
                                                }
                                                name={fieldPassword}
                                                defaultValue=""
                                                control={control}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Box order={2}>
                                            <Button type="submit" variant="contained" color="primary">
                                                Login
                                            </Button>
                                        </Box>
                                        <Box order={1}>
                                            <Link target="_blank" href="http://ausmash.com.au/account/recoverpassword">
                                                Forgot password?
                                            </Link>
                                        </Box>
                                    </Box>
                                </form>
                            </Paper>
                            <Box textAlign="center" mt={2}>
                                <RouteLink to={RouteList.About}>What is this?</RouteLink>
                            </Box>
                        </Fragment>
                    )}
                </Box>
            </div>
        </Page>
    );
};
