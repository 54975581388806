import Dexie from "dexie";
import { nameof } from "../utils/Helpers";
import { ApiRegionIndexItem } from "../models/regions/ApiRegionIndexItem";
import { ApiPocketEloItem } from "../models/elo/ApiPocketEloItem";
import { ApiPocketGame } from "../models/games/ApiPocketGame";
import { ApiPocketCharacter } from "../models/characters/ApiPocketCharacter";
import { DataStorageState } from "../models/local/DataStorageState";
import { LocalService } from "../services/LocalService";
import { ApiPocketPlayerStats } from "../models/players/ApiPocketPlayerStats";
import { ApiPocketPlayerWinRate } from "../models/players/ApiPlayerWinRateIndexItem";
import { ApiPocketPlayerResults } from "../models/players/ApiPocketPlayerResults";
import { ApiPocketPlayerVideos } from "../models/players/ApiPocketPlayerVideos";
import { ApiPocketPlayerMatches } from "../models/players/ApiPocketPlayerMatches";
import { ApiPocketResultItem } from "../models/results/ApiPocketResultItem";
import { ApiPocketResultMatches } from "../models/results/ApiPocketResultMatches";
import { ApiPocketResultPlacings } from "../models/results/ApiPocketResultPlacings";

export class SmashDatabase extends Dexie {
    regions: Dexie.Table<ApiRegionIndexItem, number>;
    games: Dexie.Table<ApiPocketGame, number>;
    elos: Dexie.Table<ApiPocketEloItem, number>;
    characters: Dexie.Table<ApiPocketCharacter, number>;
    statuses: Dexie.Table<DataStorageState, string>;
    players: Dexie.Table<ApiPocketPlayerStats, number>;
    playerWinRates: Dexie.Table<ApiPocketPlayerWinRate, number>;
    playerResults: Dexie.Table<ApiPocketPlayerResults, number>;
    playerVideos: Dexie.Table<ApiPocketPlayerVideos, number>;
    playerMatches: Dexie.Table<ApiPocketPlayerMatches, number>;
    results: Dexie.Table<ApiPocketResultItem, number>;
    resultMatches: Dexie.Table<ApiPocketResultMatches, number>;
    resultPlacings: Dexie.Table<ApiPocketResultPlacings, number>;

    constructor() {
        super("SmashDatabase");

        // regions
        const nameRegionId = nameof<ApiRegionIndexItem>("ID");

        // elo
        const nameEloId = nameof<ApiPocketEloItem>("ID");
        const nameEloGame = "game";
        const nameEloGameID = nameof<ApiPocketEloItem>("GameID");
        const nameEloElo = nameof<ApiPocketEloItem>("Elo");

        // games
        const nameGameID = nameof<ApiPocketGame>("ID");
        const nameGameSortOrder = nameof<ApiPocketGame>("SortOrder");

        // characters
        const nameCharacterID = nameof<ApiPocketCharacter>("ID");
        const nameCharacterGameID = nameof<ApiPocketCharacter>("GameID");
        const nameCharacterName = nameof<ApiPocketCharacter>("Name");

        // status 
        const nameStatusId = nameof<DataStorageState>("Id");

        // players
        const namePlayerID = nameof<ApiPocketPlayerStats>("ID");
        const namePlayerGameID = nameof<ApiPocketPlayerStats>("GameID");

        // player win rates
        const namePlayerWinRatePlayerID = nameof<ApiPocketPlayerWinRate>("PlayerID");
        const namePlayerWinRateGameID = nameof<ApiPocketPlayerWinRate>("GameID");

        // player results
        const namePlayerResultsPlayerID = nameof<ApiPocketPlayerResults>("PlayerID");
        const namePlayerResultsGameID = nameof<ApiPocketPlayerResults>("GameID");

        // player videos
        const namePlayerVideosPlayerID = nameof<ApiPocketPlayerVideos>("PlayerID");
        const namePlayerVideosGameID = nameof<ApiPocketPlayerVideos>("GameID");

        // player matches
        const namePlayerMatchesPlayerID = nameof<ApiPocketPlayerMatches>("PlayerID");
        const namePlayerMatchesGameID = nameof<ApiPocketPlayerMatches>("GameID");

        // result items
        const nameResultItemID = nameof<ApiPocketResultItem>("ID");
        const nameResultItemGameID = nameof<ApiPocketResultItem>("GameID");

        // result matches
        const nameResultMatchesTourneyID = nameof<ApiPocketResultMatches>("TourneyID");
        const nameResultMatchesGameID = nameof<ApiPocketResultMatches>("GameID");

        // result placings
        const nameResultPlacingsTourneyID = nameof<ApiPocketResultPlacings>("TourneyID");
        const nameResultPlacingsGameID = nameof<ApiPocketResultPlacings>("GameID");

        this.version(15).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
            playerVideos: `[${namePlayerVideosPlayerID}+${namePlayerVideosGameID}]`,
            playerMatches: `[${namePlayerMatchesPlayerID}+${namePlayerMatchesGameID}]`,
            results: `++${nameResultItemID}, ${nameResultItemGameID}`,
            resultMatches: `[${nameResultMatchesTourneyID}+${nameResultMatchesGameID}]`,
            resultPlacings: `[${nameResultPlacingsTourneyID}+${nameResultPlacingsGameID}]`,
        });

        this.version(14).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
            playerVideos: `[${namePlayerVideosPlayerID}+${namePlayerVideosGameID}]`,
            playerMatches: `[${namePlayerMatchesPlayerID}+${namePlayerMatchesGameID}]`,
            results: `++${nameResultItemID}, ${nameResultItemGameID}`,
            resultMatches: `[${nameResultMatchesTourneyID}+${nameResultMatchesGameID}]`,
        });

        this.version(13).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
            playerVideos: `[${namePlayerVideosPlayerID}+${namePlayerVideosGameID}]`,
            playerMatches: `[${namePlayerMatchesPlayerID}+${namePlayerMatchesGameID}]`,
            results: `++${nameResultItemID}, ${nameResultItemGameID}`,
        });

        this.version(12).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
            playerVideos: `[${namePlayerVideosPlayerID}+${namePlayerVideosGameID}]`,
            playerMatches: `[${namePlayerMatchesPlayerID}+${namePlayerMatchesGameID}]`,
        });

        this.version(11).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
            playerVideos: `[${namePlayerVideosPlayerID}+${namePlayerVideosGameID}]`,
        });

        this.version(10).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
            playerResults: `[${namePlayerResultsPlayerID}+${namePlayerResultsGameID}]`,
        });

        this.version(9).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
            playerWinRates: `[${namePlayerWinRatePlayerID}+${namePlayerWinRateGameID}]`,
        });

        this.version(8).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
            players: `[${namePlayerGameID}+${namePlayerID}]`,
        });

        this.version(7).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
        }).upgrade(async() => { 
            localStorage.removeItem(LocalService.KeyGame);
            await this.games.clear();
        });

        this.version(6).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
            statuses: `++${nameStatusId}`,
        });

        this.version(5).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}, ${nameCharacterName}`,
        });

        this.version(4).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`,
            characters: `++${nameCharacterID}, ${nameCharacterGameID}`,
        }).upgrade(async () => {
            await this.elos.clear();
        });

        this.version(3).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`
        }).upgrade(async () => {
            await this.elos.clear();
        });

        this.version(2).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGame}.${nameGameID}, ${nameEloElo}`,
            games: `++${nameGameID}, ${nameGameSortOrder}`
        });

        this.version(1).stores({
            regions: `++${nameRegionId}`,
            elos: `++${nameEloId}, ${nameEloGame}.${nameGameID}`,
            games: `++${nameGameID}`
        });

        this.regions = this.table("regions");
        this.elos = this.table("elos");
        this.games = this.table("games");
        this.characters = this.table("characters");
        this.statuses = this.table("statuses");
        this.players = this.table("players");
        this.playerWinRates = this.table("playerWinRates");
        this.playerResults = this.table("playerResults");
        this.playerVideos = this.table("playerVideos");
        this.playerMatches = this.table("playerMatches");
        this.results = this.table("results");
        this.resultMatches = this.table("resultMatches");
        this.resultPlacings = this.table("resultPlacings");
    }
}
