import React, { FC, Fragment, useContext } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { IOfflineData } from "../hooks/useOfflineData";
import { GlobalContext } from "../contexts/GlobalContext";

type Props = {
    status: IOfflineData
}

export const LoadingStatus: FC<Props> = (props: Props) => {
    const { isLoading } = useContext(GlobalContext);

    return (
        <Fragment>
            {props.status.success === false && isLoading === false && props.status.lastUpdated == null ? (
                <Box>
                    <Alert severity="error">Could not connect to server</Alert>
                </Box>
            ) : (
                isLoading === true && (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <CircularProgress />
                    </Box>
                )
            )}
        </Fragment>
    );
}