import { HomePage } from "./pages/HomePage";
import { EloIndexPage } from "./pages/elo/EloIndexPage";
import { PlayerStatsPage } from "./pages/players/PlayerStatsPage";
import { PlayerWinRatesPage } from "./pages/players/PlayerWinRatesPage";
import { PlayerResultsPage } from "./pages/players/PlayerResultsPage";
import { PlayerVideosPage } from "./pages/players/PlayerVideosPage";
import { PlayerMatchesPage } from "./pages/players/PlayerMatchesPage";
import { ResultsIndexPage } from "./pages/results/ResultsIndexPage";
import { ResultMatchesPage } from "./pages/results/ResultMatchesPage";
import { ResultPlacingsPage } from "./pages/results/ResultPlacingsPage";
import { ProfileSettingsPage } from "./pages/profile/ProfileSettingsPage";
import { ProfileGamePage } from "./pages/profile/ProfileGamePage";
import { AboutPage } from "./pages/AboutPage";
import { AccountLoginPage } from "./pages/account/AccountLoginPage";

export const RouteList = {
    AccountLogin: "/account/login",
    Elo: "/elo",
    Results: "/results",
    ResultMatches: (id?: string | null) => `/result/matches/${id ?? ":id"}`,
    ResultPlacings: (id?: string | null) => `/result/placings/${id ?? ":id"}`,
    PlayerWinRates: (id?: string | null) => `/player/winrates/${id ?? ":id"}`,
    PlayerResults: (id?: string | null) => `/player/results/${id ?? ":id"}`,
    PlayerMatches: (id?: string | null) => `/player/matches/${id ?? ":id"}`,
    PlayerVideos: (id?: string | null) => `/player/videos/${id ?? ":id"}`,
    Player: (id?: string | null) => `/player/${id ?? ":id"}`,
    ProfileSettings: "/profile/settings",
    ProfileGame: "/profile/game",
    About: "/about",
    Home: "/",
};

export const RouteTable = [
    { path: RouteList.AccountLogin, Component: AccountLoginPage },
    { path: RouteList.Elo, Component: EloIndexPage },
    { path: RouteList.Results, Component: ResultsIndexPage },
    { path: RouteList.ResultMatches(), Component: ResultMatchesPage },
    { path: RouteList.ResultPlacings(), Component: ResultPlacingsPage },
    { path: RouteList.PlayerWinRates(), Component: PlayerWinRatesPage },
    { path: RouteList.PlayerResults(), Component: PlayerResultsPage },
    { path: RouteList.PlayerMatches(), Component: PlayerMatchesPage },
    { path: RouteList.PlayerVideos(), Component: PlayerVideosPage },
    { path: RouteList.Player(), Component: PlayerStatsPage },
    { path: RouteList.ProfileSettings, Component: ProfileSettingsPage },
    { path: RouteList.ProfileGame, Component: ProfileGamePage },
    { path: RouteList.About, Component: AboutPage },
    { path: RouteList.Home, Component: HomePage },
];
