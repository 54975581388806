import React, { FC, useContext } from "react";
import { Page } from "../components/Page";
import { Typography, makeStyles, Theme, createStyles, Box, Button, Paper } from "@material-ui/core";
import { GlobalContext } from "../contexts/GlobalContext";
import ImgLogo from "../img/logo-large.png";
import { RouteList } from "../Routes";
import { Link as RouteLink } from "react-router-dom";
import ImgPocket from "../img/pocket-192.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
            maxWidth: 400,
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            minHeight: "100%",
            [theme.breakpoints.up("sm")]: {
                display: "flex",
            },
        },
        card: {
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            marginBottom: theme.spacing(2),
            textAlign: "center",
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(5),
                marginBottom: theme.spacing(3),
            },
        },
        box: {
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
                marginBottom: theme.spacing(3),
            },
        },
        boxInner: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

export const AboutPage: FC = () => {
    const { isAuthenticated } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <Page showFrame={false}>
            <div className={classes.container}>
                <Box className={classes.box} display="flex" flexDirection="row" justifyContent="center">
                    <Box mr={2}>
                        <img alt="" src={ImgLogo} />
                    </Box>
                    <Box>
                        <img alt="" src={ImgPocket} height="48" />
                    </Box>
                </Box>

                <Box className={classes.box}>
                    <Box className={classes.boxInner}>
                        <Typography variant="h5" component="h1">
                            <strong>Ausmash Pocket</strong> is a <strong>fast</strong> way to access Ausmash data.
                        </Typography>
                    </Box>
                </Box>

                <Paper elevation={0} variant="outlined" className={classes.card}>
                    <strong>Ausmash Pocket</strong> saves Ausmash data onto your device so that it can be accessed again quickly later. You can even access
                    the data if you don't have a current internet connection.
                </Paper>

                {isAuthenticated ? (
                    <Button color="primary" variant="contained" component={RouteLink} to={RouteList.Home}>
                        Continue
                    </Button>
                ) : (
                    <Button color="primary" variant="contained" component={RouteLink} to={RouteList.AccountLogin}>
                        Login
                    </Button>
                )}
            </div>
        </Page>
    );
};
