import React, { useEffect, FC, PropsWithChildren, useContext, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { RouteList } from "../Routes";
import { GlobalContext } from "../contexts/GlobalContext";

type Props = {
    auth?: "public" | "private" | "all" | "privateWithGame";
    showFrame?: boolean;
};

export const Page: FC<Props> = (props: PropsWithChildren<Props>) => {
    const { setCurrentShowFrame, setCurrentSideNavMobileOpen, isAuthenticated, game, setCurrentBottomNavigation, setCurrentAllowInstallBtn } = useContext(
        GlobalContext
    );

    useEffect(() => {
        // scroll to top
        window.scroll(0, 0);

        // handle frame
        if (props.showFrame != null) {
            setCurrentShowFrame(props.showFrame);
        }

        // close side nav
        setCurrentSideNavMobileOpen(false);

        // no bottom frame
        setCurrentBottomNavigation(null);

        // hide install btn by default
        setCurrentAllowInstallBtn(false);
    }, [props.showFrame, setCurrentShowFrame, setCurrentSideNavMobileOpen, setCurrentBottomNavigation, setCurrentAllowInstallBtn]);

    return (
        <Fragment>
            {props.auth === "privateWithGame" && game == null && isAuthenticated ? (
                <Redirect to={RouteList.ProfileGame} />
            ) : props.auth === "privateWithGame" && !isAuthenticated ? (
                <Redirect to={RouteList.AccountLogin} />
            ) : props.auth === "public" && isAuthenticated ? (
                <Redirect to={RouteList.ProfileGame} />
            ) : (
                props.children
            )}
        </Fragment>
    );
};

Page.defaultProps = {
    auth: "private",
    showFrame: true,
};
