import { useState } from "react";

export const useFormState = (defaultLoading: boolean = false) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(defaultLoading);
    const [failure, setFailure] = useState(false);

    const confirmServerError = () => {
        setFailure(true);
        setLoading(false);
    };

    const handleFormResponse = (
        success: boolean,
        errors: {
            [key: string]: string[];
        },
        setError: (name: string, type: string, message?: string) => void
    ) => {
        if (success) {
            setSuccess(true);
        }
        for (let key in errors) {
            errors[key].forEach(e => {
                setError(key, "server", e);
            });
        }

        setLoading(false);
    };

    return {
        success,
        setSuccess,
        loading,
        setLoading,
        failure,
        setFailure,
        confirmServerError,
        handleFormResponse
    };
};
