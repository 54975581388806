import * as yup from "yup";

export interface AccountLoginModel {
    UserName: string;
    Password: string;
};

export interface AccountLoginModelResult {
    Key: string;
}

export const AccountLoginModelValidationSchema = yup.object().shape<AccountLoginModel>({
    UserName: yup
        .string()
        .required("The username field is required"),
    Password: yup.string().required("The password field is required"),
});