import React, { FC, useContext, useEffect, Fragment, useState } from "react";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { Page } from "../../components/Page";
import { makeStyles, createStyles, Box, Paper } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { PlayersService } from "../../services/PlayersService";
import { PlayerHeader } from "../../components/PlayerHeader";
import { PlayerNavigation } from "../../components/PlayerNavigation";
import { LoadingStatus } from "../../components/LoadingStatus";
import { RegionButton } from "../../components/RegionButton";
import { ApiPocketPlayerVideos, ApiPocketPlayerVideoEvent, ApiPocketPlayerVideoEventMatch } from "../../models/players/ApiPocketPlayerVideos";
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from "react-virtualized";
import { RegionIcon } from "../../components/RegionIcon";
import { CharacterButton } from "../../components/CharacterButton";
import { PlayerBreadcrumb } from "../../components/breadcrumbs/PlayerBreadcrumb";

type Props = {
    id: number;
};

const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            width: "100%",
            height: "100%",
            display: "flex",
        },
        event: {},
        matchPlayer: {
            width: "50%",
            textAlign: "center",
        },
        match: {
            borderTop: "1px solid rgb(224, 224, 224)",
            "&:last-child": {
                borderBottom: "1px solid rgb(224, 224, 224)",
            },
        },
    })
);

export const PlayerVideosPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation, region, character } = useContext(GlobalContext);

    const [events, setEvents] = useState<Array<ApiPocketPlayerVideoEvent>>([]);

    const statusKey = game ? LocalService.KeyPlayerVideos(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return PlayersService.getVideos(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerVideos>({} as ApiPocketPlayerVideos);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getPlayerVideos(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerVideos>({} as ApiPocketPlayerVideos);
    };

    const { data, status } = useLiveData<ApiPocketPlayerVideos | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    useEffect(() => {
        setCurrentNavbarText("Player");

        if (data) {
            setCurrentBottomNavigation(<PlayerNavigation playerId={data.PlayerID} value={4} />);
        }
    }, [setCurrentNavbarText, setCurrentBottomNavigation, data]);

    useEffect(() => {
        if (data) {
            let newData = data.Events;

            if (region) {
                newData = newData.filter((x) => x.RegionShort === region.Short);
            }

            if (character) {
                newData = newData.filter((e) => e.PlayerCharacterIDs && e.PlayerCharacterIDs.includes(character.ID));
            }

            setEvents(newData);
        }
    }, [data, region, character]);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    return (
        <Page >
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {data ? (
                    <Fragment>
                        <PlayerBreadcrumb text="Videos" />

                        <Box justifyContent="space-between" display="flex" mb={2} alignItems="center">
                            <PlayerHeader name={data.PlayerName} />

                            <Box display="flex" flexDirection="row">
                                <Box ml={2}>
                                    <RegionButton />
                                </Box>

                                <Box ml={2}>
                                    <CharacterButton />
                                </Box>
                            </Box>
                        </Box>

                        <Box maxWidth={500} height="100%">
                            {events.length > 0 ? (
                                <Paper className={classes.paper}>
                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <List
                                                height={height}
                                                width={width}
                                                deferredMeasurementCache={cache}
                                                rowCount={events.length}
                                                rowHeight={cache.rowHeight}
                                                rowRenderer={({ key, index, style, parent }) => {
                                                    const obj = events[index];

                                                    return (
                                                        <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
                                                            <Box style={style}>
                                                                <Box className={classes.event} p={1} display="flex" alignItems="center">
                                                                    <Box pr={2}>
                                                                        <RegionIcon short={obj.RegionShort} />
                                                                    </Box>
                                                                    <Box>
                                                                        <strong>{obj.TourneyName}</strong>
                                                                        {obj.EventName !== obj.TourneyName && (
                                                                            <Fragment>
                                                                                <br />
                                                                                {obj.EventName}
                                                                            </Fragment>
                                                                        )}
                                                                        <div>
                                                                            <small>{moment(obj.Date).fromNow()}</small>
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                                {character ? (
                                                                    <Fragment>
                                                                        {obj.Matches.filter(
                                                                            (x) => x.PlayerCharacterIDs && x.PlayerCharacterIDs.includes(character.ID)
                                                                        ).map((m) => {
                                                                            return <PlayerEventMatchVideo match={m} key={m.MatchID} />;
                                                                        })}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {obj.Matches.map((m) => {
                                                                            return <PlayerEventMatchVideo match={m} key={m.MatchID} />;
                                                                        })}
                                                                    </Fragment>
                                                                )}
                                                            </Box>
                                                        </CellMeasurer>
                                                    );
                                                }}
                                            />
                                        )}
                                    </AutoSizer>
                                </Paper>
                            ) : (
                                <Alert color="warning">This player has no videos to display</Alert>
                            )}
                        </Box>
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};

type PlayerEventMatchVideoProps = {
    match: ApiPocketPlayerVideoEventMatch;
};

const PlayerEventMatchVideo: FC<PlayerEventMatchVideoProps> = (props: PlayerEventMatchVideoProps) => {
    const classes = useStyles();

    const { match } = props;

    return (
        <Box display="flex" flexDirection="row" p={1} className={classes.match}>
            <a className={classes.paper} rel="noopener noreferrer" target="_blank" href={match.YouTubeUrl}>
                <Box width="100%">
                    <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                        <Box display="flex" alignItems="center">
                            <Box mr={2}>{match.WinnerRegionShort ? <RegionIcon short={match.WinnerRegionShort} /> : <RegionIcon short="AUS" />}</Box>

                            {match.WinnerName}
                        </Box>
                        <Box ml="auto" pl={2} display="flex" flexDirection="row">
                            {match.WinnerCharacters.map((c) => (
                                <Box key={c.ID} ml={1}>
                                    <img key={c.ID} alt="" src={c.ImageUrl} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                        <Box display="flex" alignItems="center">
                            <Box mr={2}>{match.LoserRegionShort ? <RegionIcon short={match.LoserRegionShort} /> : <RegionIcon short="AUS" />}</Box>

                            {match.LoserName}
                        </Box>
                        <Box ml="auto" pl={2} display="flex" flexDirection="row">
                            {match.LoserCharacters.map((c) => (
                                <Box key={c.ID} ml={1}>
                                    <img key={c.ID} alt="" src={c.ImageUrl} />
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </a>
        </Box>
    );
};
