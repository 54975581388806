import React, { FC, useContext, useEffect, Fragment, useState } from "react";
import { Page } from "../../components/Page";
import { makeStyles, createStyles, Box, Paper, Typography } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { PlayersService } from "../../services/PlayersService";
import { PlayerHeader } from "../../components/PlayerHeader";
import { PlayerNavigation } from "../../components/PlayerNavigation";
import { LoadingStatus } from "../../components/LoadingStatus";
import { ApiPocketPlayerResults, ApiPocketPlayerResultItem } from "../../models/players/ApiPocketPlayerResults";
import { RegionButton } from "../../components/RegionButton";
import { CellMeasurerCache, List, AutoSizer, CellMeasurer } from "react-virtualized";
import { RegionIcon } from "../../components/RegionIcon";
import moment from "moment";
import { CharacterButton } from "../../components/CharacterButton";
import { PlayerBreadcrumb } from "../../components/breadcrumbs/PlayerBreadcrumb";
import { RouteList } from "../../Routes";
import { Link as RouterLink } from "react-router-dom";

type Props = {
    id: number;
};

const useStyles = makeStyles(() =>
    createStyles({
        paper: {
            width: "100%",
            height: "100%",
        },
        item: {
            borderTop: "1px solid rgb(224, 224, 224)",
            "&:first-child": {
                borderTop: "none",
            },
        },
    })
);

export const PlayerResultsPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation, region, character } = useContext(GlobalContext);

    const [results, setResults] = useState<Array<ApiPocketPlayerResultItem>>([]);

    const statusKey = game ? LocalService.KeyPlayerStats(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return PlayersService.getResults(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerResults>({} as ApiPocketPlayerResults);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getPlayerResults(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerResults>({} as ApiPocketPlayerResults);
    };

    const { data, status } = useLiveData<ApiPocketPlayerResults | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    useEffect(() => {
        setCurrentNavbarText("Player");

        if (data) {
            setCurrentBottomNavigation(<PlayerNavigation playerId={data.PlayerID} value={2} />);
        }
    }, [setCurrentNavbarText, setCurrentBottomNavigation, data]);

    useEffect(() => {
        if (data) {
            let newData = data.Items;

            if (region) {
                newData = newData.filter((x) => x.RegionShort === region.Short);
            }

            if (character) {
                newData = newData.filter((x) => x.Characters && x.Characters.find((c) => c.ID === character.ID) != null);
            }

            setResults(newData);
        }
    }, [data, region, character]);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    return (
        <Page >
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {data ? (
                    <Fragment>
                        <PlayerBreadcrumb text="Results" />

                        <Box justifyContent="space-between" display="flex" mb={2} alignItems="center">
                            <PlayerHeader name={data.PlayerName} />

                            <Box display="flex" flexDirection="row">
                                <Box ml={2}>
                                    <RegionButton />
                                </Box>

                                <Box ml={2}>
                                    <CharacterButton />
                                </Box>
                            </Box>
                        </Box>

                        <Box maxWidth={500} height="100%">
                            <Paper className={classes.paper}>
                                <AutoSizer>
                                    {({ width, height }) => (
                                        <List
                                            height={height}
                                            width={width}
                                            rowCount={results.length}
                                            rowHeight={cache.rowHeight}
                                            rowRenderer={({ key, index, style, parent }) => {
                                                const obj = results[index];

                                                return (
                                                    <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
                                                        <Box className={classes.item} style={style}>
                                                            <Box p={1} display="flex" flexDirection="row" width="100%">
                                                                <Box flexShrink={0} width={40} display="flex" alignItems="center">
                                                                    <RegionIcon short={obj.RegionShort} />
                                                                </Box>
                                                                <Box
                                                                    flexGrow={1}
                                                                    display="flex"
                                                                    alignItems="flex-start"
                                                                    flexDirection="column"
                                                                    justifyContent="center"
                                                                    pr={1}
                                                                >
                                                                    <Box>
                                                                        <RouterLink to={RouteList.ResultPlacings(obj.TourneyID.toString())}>
                                                                            {obj.TourneyName}
                                                                        </RouterLink>
                                                                    </Box>
                                                                    {obj.EventName !== obj.TourneyName && <Box>{obj.EventName}</Box>}
                                                                    <Box
                                                                        display="flex"
                                                                        flexDirection="row"
                                                                        justifyContent="space-between"
                                                                        width="100%"
                                                                        alignItems="center"
                                                                    >
                                                                        <Box>
                                                                            <Typography color="textSecondary" variant="caption">
                                                                                {moment(obj.Date).fromNow()}
                                                                            </Typography>
                                                                        </Box>
                                                                        {obj.Characters && obj.Characters.length > 0 && (
                                                                            <Box display="flex" alignItems="center">
                                                                                {obj.Characters.map((c) => (
                                                                                    <img alt="" src={c.ImageUrl} key={c.ID} />
                                                                                ))}
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                <Box width={40} flexShrink={0} display="flex" justifyContent="flex-end" alignItems="center">
                                                                    {obj.Place}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </CellMeasurer>
                                                );
                                            }}
                                        />
                                    )}
                                </AutoSizer>
                            </Paper>
                        </Box>
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};
