import { useState, useCallback, useEffect, ReactNode } from "react";
import { IGlobalContext } from "../contexts/GlobalContext";
import { ApiPocketGame } from "../models/games/ApiPocketGame";
import { LocalService } from "../services/LocalService";
import { RegionsService } from "../services/RegionsService";
import { ApiRegionIndexItem } from "../models/regions/ApiRegionIndexItem";
import { ApiPocketCharacter } from "../models/characters/ApiPocketCharacter";
import { CharactersService } from "../services/CharactersService";
import { GamesService } from "../services/GamesService";

export const useGlobal = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(LocalService.isAuthenticated());
    const [isLoading, setIsLoading] = useState(false);
    const [game, setGame] = useState<ApiPocketGame | null>(LocalService.getGame());
    const [showFrame, setShowFrame] = useState(true);
    const [navbarText, setNavbarText] = useState("");
    const [sideNavMobileOpen, setSideNavMobileOpen] = useState(false);
    const [region, setRegion] = useState<ApiRegionIndexItem | null>(null);
    const [character, setCharacter] = useState<ApiPocketCharacter | null>(null);
    const [playerSearch, setPlayerSearch] = useState("");
    const [resultSearch, setResultSearch] = useState("");
    const [bottomNavigation, setBottomNavigation] = useState<ReactNode>(null);
    const [allowInstallBtn, setAllowInstallBtn] = useState<boolean>(false);
   
    const [regions, setRegions] = useState<Array<ApiRegionIndexItem>>([]);
    const [characters, setCharacters] = useState<Array<ApiPocketCharacter>>([]);
    const [games, setGames] = useState<Array<ApiPocketGame>>([]);

    const setCurrentIsAuthenticated = useCallback((currentIsAuthenticated: boolean): void => {
        setIsAuthenticated(currentIsAuthenticated);
    }, []);

    const setCurrentGame = useCallback((currentGame: ApiPocketGame | null): void => {
        setGame(currentGame);
        LocalService.setGame(currentGame);
    }, []);

    const setCurrentRegions = useCallback((currentRegions: Array<ApiRegionIndexItem>): void => {
        setRegions(currentRegions);
        LocalService.setRegions(currentRegions);
    }, []);

    const setCurrentGames = useCallback((currentGames: Array<ApiPocketGame>): void => {
        setGames(currentGames);
        LocalService.setGames(currentGames);
    }, [])

    const setCurrentCharacters = useCallback((currentCharacters: Array<ApiPocketCharacter>): void => {
        setCharacters(currentCharacters);
        LocalService.setCharacters(currentCharacters);
    }, []);

    const setCurrentShowFrame = useCallback((currentShowFrame): void => {
        setShowFrame(currentShowFrame);
    }, []);

    const setCurrentNavbarText = useCallback((currentText: string) => {
        setNavbarText(currentText);
    }, []);

    const setCurrentSideNavMobileOpen = useCallback((currentSideNavMobileOpen: boolean) => {
        setSideNavMobileOpen(currentSideNavMobileOpen);
    }, []);

    const setCurrentIsLoading = useCallback((currentIsLoading: boolean) => {
        setIsLoading(currentIsLoading);
    }, []);

    const setCurrentRegion = useCallback((currentRegion: ApiRegionIndexItem | null) => {
        setRegion(currentRegion);
    }, []);

    const setCurrentCharacter = useCallback((currentCharacter: ApiPocketCharacter | null) => {
        setCharacter(currentCharacter);
    }, []);

    const setCurrentPlayerSearch = useCallback((currentPlayerSearch: string) => {
        setPlayerSearch(currentPlayerSearch);
    }, []);

    const setCurrentResultSearch = useCallback((currentResultSearch: string) => {
        setResultSearch(currentResultSearch);
    }, []);

    const setCurrentBottomNavigation = useCallback((currentBottomNavigation: ReactNode | null) => {
        setBottomNavigation(currentBottomNavigation);
    }, []);

    const setCurrentAllowInstallBtn = useCallback((currentAllowInstallBtn: boolean) => {
        setAllowInstallBtn(currentAllowInstallBtn);
    }, []);

    useEffect(() => {
        let mounted = true;

        // get regions
        LocalService.getRegions().then((r) => {
            if (r.length > 0) {
                if (mounted) {
                    setRegions(r);
                }
            } else {
                if (LocalService.isAuthenticated()) {
                    RegionsService.getRegions().then((x) => {
                        if (mounted) {
                            setCurrentRegions(x);
                        }
                    });
                }
            }
        });

        // get characters
        LocalService.getCharacters().then((c) => {
            if (c.length > 0) {
                if (mounted) {
                    setCharacters(c);
                }
            } else {
                if (LocalService.isAuthenticated()) {
                    CharactersService.getCharacters().then((x) => {
                        if (mounted) {
                            setCurrentCharacters(x);
                        }
                    });
                }
            }
        });

        // get games
        LocalService.getGames().then((g) => {
            if (g.length > 0) {
                if (mounted) {
                    setGames(g);
                }
            } else {
                if (LocalService.isAuthenticated()) {
                    GamesService.getGames().then((x) => {
                        if (mounted) {
                            setCurrentGames(x);
                        }
                    })
                }
            }
        })

        return () => {
            mounted = false;
        };
    }, [setCurrentRegions, setCurrentCharacters, setCurrentGames, isAuthenticated]);

    return {
        game,
        setCurrentGame,
        regions,
        characters,
        games,
        showFrame,
        setCurrentShowFrame,
        navbarText,
        setCurrentNavbarText,
        sideNavMobileOpen,
        setCurrentSideNavMobileOpen,
        isAuthenticated,
        setCurrentIsAuthenticated,
        isLoading,
        setCurrentIsLoading,
        region,
        setCurrentRegion,
        character,
        setCurrentCharacter,
        playerSearch,
        setCurrentPlayerSearch,
        resultSearch,
        setCurrentResultSearch,
        bottomNavigation,
        setCurrentBottomNavigation,
        allowInstallBtn,
        setCurrentAllowInstallBtn,
    };
};
