import React, { useState, FC } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { LocalService } from "../services/LocalService";

export const AlertDialog: FC = () => {
    const hasOpenedBefore = LocalService.getProfileSetting("IntroModal");
    const [open, setOpen] = useState(!hasOpenedBefore);

    const handleClose = () => {
        setOpen(false);
        LocalService.setProfileSetting("IntroModal", true);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>What is Ausmash Pocket?</DialogTitle>
                <DialogContent>
                    <DialogContentText>Ausmash Pocket saves Smash data to your device so it can be accessed quickly again later.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
