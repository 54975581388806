import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";

type Props = {
    name: string;
};

export const PlayerHeader: FC<Props> = (props: Props) => {
    return (
        <Box overflow="hidden">
            <Typography variant="h6" component="h1" noWrap>
                {props.name}
            </Typography>
        </Box>
    );
};
