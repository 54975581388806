import React, { FC, ReactNode } from "react";
import { Typography, Box } from "@material-ui/core";

type Props = {
    text: string;
    subtext?: string | ReactNode;
};

export const H1: FC<Props> = (props: Props) => {
    return (
        <Box mb={3}>
            <Typography variant="h5" component="h1">
                {props.text}
            </Typography>
            {props.subtext && (
                <Box mt={1}>
                    <Typography component="small">{props.subtext}</Typography>
                </Box>
            )}
        </Box>
    );
};
