import React, { FC, ReactNode, Fragment } from "react";
import clsx from "clsx";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { AutoSizer, Column, Table, TableCellRenderer, TableHeaderProps } from "react-virtualized";
import { Row, MuiVirtualizedTableProps } from "../models/tables/IReactVirtualisedTable";
import { getDeepPropertyByString } from "../utils/Helpers";
import { RegionIcon } from "../components/RegionIcon";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        flexContainer: {
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
        },
        tableRow: {
            cursor: "pointer",
        },
        tableRowHover: {
            "&:hover": {
                backgroundColor: theme.palette.grey[200],
            },
        },
        tableCell: {
            flex: 1,
        },
        noClick: {
            cursor: "initial",
        },
        link: {
            cursor: "pointer",
            textDecoration: "underline",
            color: theme.palette.primary.main,
        },
    })
);

export const MuiVirtualizedTable: FC<MuiVirtualizedTableProps> = (props: MuiVirtualizedTableProps) => {
    const classes = useStyles();

    const getRowClassName = ({ index }: Row) => {
        const { onRowClick } = props;

        return clsx(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    const cellRenderer: TableCellRenderer = ({ cellData, columnIndex }) => {
        const { columns, rowHeight, onRowClick } = props;

        const renderCell = (content: ReactNode) => (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                    [classes.link]: columns[columnIndex].link,
                })}
                variant="body"
                style={{ height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
            >
                {content}
            </TableCell>
        );

        // very specific hack
        // TODO: move this out of here somehow
        if (columns[columnIndex].region) {
            return renderCell(<RegionIcon short={cellData} />);
        }

        if (columns[columnIndex].date) {
            return renderCell(<span>{moment(cellData).format("DD/MM/YYYY")}</span>);
        }

        if (columns[columnIndex].dataKey === "CharacterImageUrl" && cellData && cellData.length) {
            return renderCell(<img alt="" src={cellData} />);
        }

        return renderCell(cellData);
    };

    const headerRenderer = ({ label, columnIndex }: TableHeaderProps & { columnIndex: number }) => {
        const { headerHeight, columns } = props;

        return (
            <TableCell
                component="div"
                className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? "right" : "left"}
            >
                <span>{label}</span>
            </TableCell>
        );
    };

    const { columns, rowHeight, headerHeight, disableHeader, ...tableProps } = props;

    return (
        <Fragment>
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        rowHeight={rowHeight!}
                        headerHeight={headerHeight!}
                        disableHeader={disableHeader!}
                        rowClassName={getRowClassName}
                        gridStyle={{
                            direction: "inherit",
                        }}
                        onRowClick={props.onRowClick}
                        {...tableProps}
                    >
                        {columns.map(({ dataKey, ...other }, index) => {
                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={(headerProps) =>
                                        headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    cellDataGetter={({ dataKey, rowData }: { dataKey: string; rowData: any }) => {
                                        return getDeepPropertyByString(rowData, dataKey);
                                    }}
                                    className={classes.flexContainer}
                                    cellRenderer={cellRenderer}
                                    dataKey={dataKey}
                                    {...other}
                                    flexGrow={1}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        </Fragment>
    );
};

MuiVirtualizedTable.defaultProps = {
    headerHeight: 36,
    rowHeight: 36,
};
