import { useState, useEffect, useCallback, DependencyList, useContext } from "react";
import { useOfflineData } from "./useOfflineData";
import moment from "moment";
import { LocalService } from "../services/LocalService";
import { GlobalContext } from "../contexts/GlobalContext";

export type Props<S> = {
    statusKey: string;
    getLocalData: () => Promise<S>;
    getLiveData: () => Promise<S>;
};

export function useLiveData<DataType>(props: Props<DataType>, deps: DependencyList) {
    // the data update frequency determine how long to wait before refreshing the data
    const dataUpdateFrequency = LocalService.getDataUpdateFrequency();

    const { setCurrentIsLoading } = useContext(GlobalContext);

    // the offline status is used to keep track of when the data was last updated or if it's being updated
    const status = useOfflineData();
    const { reset, setLastUpdated, setLoadedLocally, setSuccess } = status;

    // the data is the actual data use by both the live and local services
    const [data, setData] = useState<DataType>();

    const loadData = useCallback(() => {
        let mounted = true;

        // at the start clear everything just in case
        setCurrentIsLoading(true);
        reset();

        // first get the status to check if you should get the live data
        LocalService.getStatus(props.statusKey).then((s) => {
            // we've got the last time the status was updated, now show this to the user
            if (s.LastUpdated != null) {
                if (mounted) {
                    setLastUpdated(s.LastUpdated);
                }
            }

            // second get the local data
            props.getLocalData().then((localData) => {
                if (mounted) {
                    setData(localData);
                }

                // we only set loaded locally true if our object wasn't empty
                if (localData) {
                    const isEmptyArray = Array.isArray(localData) && localData.length === 0;

                    if (!isEmptyArray && mounted) {
                        setLoadedLocally(true);
                    }
                }

                // now we've got to determine if we actually should get the live data
                const shouldGetLiveData = (): boolean => {
                    // if it's never been updated or we have nothing locally then we need to get it
                    if (s.LastUpdated == null || localData == null) {
                        return true;
                    }

                    // only check if the data update frequency isn't set to update every load
                    if (dataUpdateFrequency.value === 0) {
                        return true;
                    }

                    // compare the difference in seconds against the data update frequency
                    const lastUpdatedMoment = moment(s.LastUpdated);
                    const currentMoment = moment(new Date());
                    const differenceInSeconds = currentMoment.diff(lastUpdatedMoment, "seconds");

                    if (dataUpdateFrequency.value < differenceInSeconds) {
                        return true;
                    }

                    return false;
                };

                // now we actually get the data if we're meant to
                if (shouldGetLiveData()) {
                    props
                        .getLiveData()
                        .then((liveData) => {
                            LocalService.setStatus({ ...s, LastUpdated: new Date() });

                            if (mounted) {
                                setLastUpdated(new Date());
                                setSuccess(true);
                                setCurrentIsLoading(false);
                                setData(liveData);
                            }
                        })
                        .catch(() => {
                            if (mounted) {
                                setSuccess(false);
                                setCurrentIsLoading(false);
                            }
                        });
                } else {
                    if (mounted) {
                        setSuccess(false);
                        setCurrentIsLoading(false);
                    }
                }
            });
        });

        return () => {
            mounted = false;
        };
    }, [props, setLoadedLocally, setCurrentIsLoading, setLastUpdated, setSuccess, dataUpdateFrequency.value, reset]);

    // we only pass the dependencies passed into the useLiveData hook here to trigger a re-render
    useEffect(loadData, deps);

    return {
        data,
        status,
    };
}
