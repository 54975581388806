import React, { FC, Fragment, useState, useRef, useEffect } from "react";
import "./css/app.scss";
import { makeStyles, Theme, createStyles, CssBaseline, Box, Hidden, Fab } from "@material-ui/core";
import { RouteTable } from "./Routes";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalContext } from "./contexts/GlobalContext";
import { useGlobal } from "./hooks/useGlobal";
import clsx from "clsx";
import { TopNav } from "./components/nav/TopNav";
import { SideNav } from "./components/nav/SideNav";
import GetAppIcon from "@material-ui/icons/GetApp";

interface BeforeInstallPromptEvent extends Event {
    readonly platforms: Array<string>;
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;
    prompt(): Promise<void>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
            width: "100%",
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            height: "100%",
            paddingTop: theme.spacing(3),
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: 500,
        },
        contentBare: {
            flexGrow: 1,
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },
        btnInstall: {
            position: "fixed",
            right: 12,
            bottom: 12,
            zIndex: 2000,
        },
        btnInstallIcon: {
            marginRight: theme.spacing(1),
        },
    })
);

export const App: FC = () => {
    const globalContext = useGlobal();
    const classes = useStyles();

    const [showInstall, setShowInstall] = useState(false);

    const deferredPrompt = useRef<BeforeInstallPromptEvent>();

    // this is all used to show a custom install button on Chrome only
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();

            if ((e as BeforeInstallPromptEvent) != null) {
                deferredPrompt.current = e as BeforeInstallPromptEvent;
            }

            setShowInstall(true);
        });

        // this fires on install PWA, even if it's done manually
        window.addEventListener("appinstalled", () => {
            // Log install to analytics
            setShowInstall(false);
        });

        // this determines on load if it's operating as an app or in the browser
        window.addEventListener("DOMContentLoaded", () => {
            if ((navigator as any).standalone) {
                setShowInstall(false);
            }
            
            if (window.matchMedia("(display-mode: standalone)").matches) {
                setShowInstall(false);
            }
        });
    }, []);

    const install = () => {
        if (deferredPrompt.current) {
            deferredPrompt.current.prompt();

            // Wait for the user to respond to the prompt
            deferredPrompt.current.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    window.location.reload(true);
                }
            });
        }
    };

    return (
        <GlobalContext.Provider value={globalContext}>
            <Router>
                <div className={classes.root}>
                    <CssBaseline />

                    {globalContext.showFrame && (
                        <Fragment>
                            <TopNav />
                            <SideNav />

                            <Hidden mdUp>
                                {showInstall && globalContext.allowInstallBtn && (
                                    <Fab variant="extended" className={classes.btnInstall} color="primary" onClick={install} size="small">
                                        <GetAppIcon className={classes.btnInstallIcon} />
                                        Install
                                    </Fab>
                                )}
                            </Hidden>
                        </Fragment>
                    )}

                    <Box display="flex" flexDirection="column" width="100%">
                        <main
                            className={clsx({
                                [classes.content]: globalContext.showFrame,
                                [classes.contentBare]: !globalContext.showFrame,
                            })}
                        >
                            {globalContext.showFrame && <div className={classes.toolbar} />}

                            <Switch>
                                {RouteTable.map(({ path, Component }) => (
                                    <Route exact path={path} key={path}>
                                        {({ match }) => {
                                            return <Component {...match?.params} />;
                                        }}
                                    </Route>
                                ))}
                            </Switch>
                        </main>
                        {globalContext.bottomNavigation}
                    </Box>
                </div>
            </Router>
        </GlobalContext.Provider>
    );
};
