// this is used to ensure that things like field names are strongly typed
export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export const toOrdinalSuffix = (int: number) => {
    const digits = [int % 10, int % 100],
        ordinals = ["st", "nd", "rd", "th"],
        oPattern = [1, 2, 3, 4],
        tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1]) ? int + ordinals[digits[0] - 1] : int + ordinals[3];
};

export const getDeepPropertyByString = (o: any, s: string) => {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};