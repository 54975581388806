import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Link } from "react-router-dom";
import { RouteList } from "../Routes";
import { faBalanceScale, faInfoCircle, faTrophy, IconDefinition, faVideo, faGamepad } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

type Props = {
    playerId: number;
    value: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
            maxWidth: 500,
        },
        btn: {
            padding: 0,
            paddingTop: "0 !important",
            minWidth: 0,
            fontSize: 16,
        },
        selected: {
            color: "white !important",
            backgroundColor: theme.palette.primary.main,
        },
        unselected: {
            backgroundColor: theme.palette.grey[300],
        },
    })
);

export const PlayerNavigation: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const action = (to: string, index: number, label: string, icon: IconDefinition) => (
        <BottomNavigationAction
            className={clsx({ [classes.selected]: props.value === index, [classes.unselected]: props.value !== index, [classes.btn]: true })}
            component={Link}
            showLabel={false}
            to={to}
            icon={<FontAwesomeIcon icon={icon} />}
        />
    );

    return (
        <BottomNavigation value={props.value} className={classes.root}>
            {action(RouteList.Player(props.playerId.toString()), 0, "Stats", faInfoCircle)}
            {action(RouteList.PlayerWinRates(props.playerId.toString()), 1, "Win rates", faBalanceScale)}
            {action(RouteList.PlayerResults(props.playerId.toString()), 2, "Results", faTrophy)}
            {action(RouteList.PlayerMatches(props.playerId.toString()), 3, "Matches", faGamepad)}
            {action(RouteList.PlayerVideos(props.playerId.toString()), 4, "Videos", faVideo)}
        </BottomNavigation>
    );
};
