import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    // <React.StrictMode>
        <App />,
    // </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (registration: ServiceWorkerRegistration) => {
        if (window.confirm("New version available.  Reload?")) {
            registration.unregister().then(() => {
                window.location.reload();
            });
        }
    }
});
