import React, { FC, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ApiPocketGame } from "../../models/games/ApiPocketGame";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Button,
    Menu,
    MenuItem,
    makeStyles,
    Theme,
    createStyles,
    ListItemIcon,
    Box,
    LinearProgress,
} from "@material-ui/core";
import { RouteList } from "../../Routes";
import MenuIcon from "@material-ui/icons/Menu";
import LogoIcon from "../../img/logo-192.png";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBarIcon: {
            maxHeight: 30,
            marginRight: theme.spacing(1),
        },
        appBarBrand: {
            color: "white",
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
        },
        appBarGameSelector: {
            marginLeft: "auto",
            backgroundColor: "white",
            marginRight: 6,
            [theme.breakpoints.up("sm")]: {
                marginRight: 0,
            },
        },
        appBarGameSelectorList: {
            minWidth: 200,
        },
        menuButton: {
            [theme.breakpoints.up("sm")]: {
                display: "none",
                marginLeft: 0,
            },
            marginLeft: -4,
        },
    })
);

export const TopNav: FC = () => {
    const classes = useStyles();

    const { games, setCurrentGame, navbarText, game, setCurrentSideNavMobileOpen, sideNavMobileOpen, isAuthenticated, isLoading } = useContext(GlobalContext);

    const [gamesEl, setGamesEl] = useState<null | HTMLElement>(null);

    const handleGamesMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGamesEl(event.currentTarget);
    };

    const handleGamesMenuClose = () => {
        setGamesEl(null);
    };

    const handleGamesMenuItemClick = (g: ApiPocketGame) => {
        setCurrentGame(g);
        handleGamesMenuClose();
    };

    const handleDrawerToggle = () => {
        setCurrentSideNavMobileOpen(!sideNavMobileOpen);
    };

    return (
        <AppBar position="fixed">
            <Box height={5}>{isLoading && <LinearProgress />}</Box>
            <Toolbar>
                <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                    <MenuIcon />
                </IconButton>

                <Link to={RouteList.Home}>
                    <img className={classes.appBarIcon} alt="Ausmash" src={LogoIcon} />
                </Link>

                <Typography className={classes.appBarBrand} variant="h6" noWrap>
                    <span className={classes.appBarBrand}>{navbarText}</span>
                </Typography>

                {isAuthenticated && (
                    <>
                        <Button className={classes.appBarGameSelector} variant="contained" onClick={handleGamesMenuClick}>
                            {game ? <img alt="" src={game.ImageUrl} /> : "Game"}
                        </Button>
                        <Menu anchorEl={gamesEl} open={Boolean(gamesEl)} keepMounted onClose={handleGamesMenuClose}>
                            {games.map((g) => {
                                return (
                                    <MenuItem key={g.ID} onClick={() => handleGamesMenuItemClick(g)}>
                                        <ListItemIcon>
                                            <img alt="" src={g.ImageUrl} />
                                        </ListItemIcon>
                                        {g.Short}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};
