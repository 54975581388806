import React, { FC, useContext, useEffect, Fragment, useState, createRef } from "react";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { Page } from "../../components/Page";
import { makeStyles, createStyles, Box, Paper, Theme, Typography } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { PlayersService } from "../../services/PlayersService";
import { PlayerHeader } from "../../components/PlayerHeader";
import { PlayerNavigation } from "../../components/PlayerNavigation";
import { LoadingStatus } from "../../components/LoadingStatus";
import { RegionButton } from "../../components/RegionButton";
import { ApiPocketPlayerMatches, ApiPocketPlayerMatchEvent, ApiPocketPlayerMatchEventMatch } from "../../models/players/ApiPocketPlayerMatches";
import { AutoSizer, List, CellMeasurerCache, CellMeasurer } from "react-virtualized";
import { RegionIcon } from "../../components/RegionIcon";
import { CharacterButton } from "../../components/CharacterButton";
import { PlayerBreadcrumb } from "../../components/breadcrumbs/PlayerBreadcrumb";
import { Link as RouterLink } from "react-router-dom";
import { RouteList } from "../../Routes";

type Props = {
    id: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: "100%",
            height: "100%",
            display: "flex",
        },
        event: {},
        matchPlayer: {
            width: "50%",
            textAlign: "center",
        },
        badge: {
            marginLeft: theme.spacing(1),
            color: theme.palette.success.main,
        },
        badgeLoss: {
            marginLeft: theme.spacing(1),
            color: theme.palette.error.main,
        },
        match: {
            borderTop: "1px solid rgb(224, 224, 224)",
            "&:last-child": {
                borderBottom: "1px solid rgb(224, 224, 224)",
            },
        },
    })
);

export const PlayerMatchesPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation, region, character } = useContext(GlobalContext);

    const cache = new CellMeasurerCache({
        fixedWidth: true,
    });

    const listRef = createRef<List>();

    const [events, setEvents] = useState<Array<ApiPocketPlayerMatchEvent>>([]);

    const statusKey = game ? LocalService.KeyPlayerMatches(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return PlayersService.getMatches(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerMatches>({} as ApiPocketPlayerMatches);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getPlayerMatches(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerMatches>({} as ApiPocketPlayerMatches);
    };

    const { data, status } = useLiveData<ApiPocketPlayerMatches | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    useEffect(() => {
        setCurrentNavbarText("Player");

        if (data) {
            setCurrentBottomNavigation(<PlayerNavigation playerId={data.PlayerID} value={3} />);
        }
    }, [setCurrentNavbarText, setCurrentBottomNavigation, data]);

    useEffect(() => {
        if (data) {
            let newData = data.Events;

            if (region) {
                newData = newData.filter((x) => x.RegionShort === region.Short);
            }

            if (character) {
                newData = newData.filter((e) => e.PlayerCharacterIDs && e.PlayerCharacterIDs.includes(character.ID));
            }

            setEvents(newData);
        }
    }, [data, region, character]);

    return (
        <Page >
            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {data ? (
                    <Fragment>
                        <PlayerBreadcrumb text="Matches" />

                        <Box justifyContent="space-between" display="flex" mb={2} alignItems="center">
                            <PlayerHeader name={data.PlayerName} />

                            <Box display="flex" flexDirection="row">
                                <Box ml={2}>
                                    <RegionButton />
                                </Box>

                                <Box ml={2}>
                                    <CharacterButton />
                                </Box>
                            </Box>
                        </Box>

                        <Box maxWidth={500} height="100%">
                            {events.length > 0 ? (
                                <Paper className={classes.paper}>
                                    <AutoSizer>
                                        {({ width, height }) => (
                                            <List
                                                ref={listRef}
                                                height={height}
                                                width={width}
                                                deferredMeasurementCache={cache}
                                                rowCount={events.length}
                                                rowHeight={cache.rowHeight}
                                                rowRenderer={({ key, index, style, parent }) => {
                                                    const obj = events[index];

                                                    return (
                                                        <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
                                                            <Box style={style}>
                                                                <Box className={classes.event} p={1} display="flex" alignItems="center">
                                                                    <Box pr={2}>
                                                                        <RegionIcon short={obj.RegionShort} />
                                                                    </Box>
                                                                    <Box>
                                                                        <RouterLink to={RouteList.ResultPlacings(obj.TourneyID.toString())}>{obj.TourneyName}</RouterLink>
                                                                        {obj.EventName !== obj.TourneyName && (
                                                                            <Fragment>
                                                                                <br />
                                                                                {obj.EventName}
                                                                            </Fragment>
                                                                        )}
                                                                        <div>
                                                                            <small>{moment(obj.Date).fromNow()}</small>
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                                {character ? (
                                                                    <Fragment>
                                                                        {obj.Matches.filter(
                                                                            (x) => x.PlayerCharacterIDs && x.PlayerCharacterIDs.includes(character.ID)
                                                                        ).map((m) => {
                                                                            return <PlayerEventMatchMatch match={m} key={m.MatchID} />;
                                                                        })}
                                                                    </Fragment>
                                                                ) : (
                                                                    <Fragment>
                                                                        {obj.Matches.map((m) => {
                                                                            return <PlayerEventMatchMatch match={m} key={m.MatchID} />;
                                                                        })}
                                                                    </Fragment>
                                                                )}
                                                            </Box>
                                                        </CellMeasurer>
                                                    );
                                                }}
                                            />
                                        )}
                                    </AutoSizer>
                                </Paper>
                            ) : (
                                <Alert color="warning">This player has no matches to display</Alert>
                            )}
                        </Box>
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};

type PlayerEventMatchMatchProps = {
    match: ApiPocketPlayerMatchEventMatch;
};

const PlayerEventMatchMatch: FC<PlayerEventMatchMatchProps> = (props: PlayerEventMatchMatchProps) => {
    const classes = useStyles();

    const { match } = props;

    return (
        <Box display="flex" flexDirection="row" p={1} className={classes.match}>
            <Box width="100%">
                <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                    <Box display="flex" alignItems="center" width="100%">
                        <Box mr={2}>{match.WinnerRegionShort ? <RegionIcon short={match.WinnerRegionShort} /> : <RegionIcon short="AUS" />}</Box>
                        <Box>
                            {match.WinnerID ? (
                                <Typography noWrap component={RouterLink} to={RouteList.Player(match.WinnerID.toString())}>
                                    {match.WinnerName}
                                </Typography>
                            ) : (
                                <Typography noWrap>{match.WinnerName}</Typography>
                            )}
                        </Box>
                        <Box>
                            {match.EloMovement && match.EloMovement !== 0 ? (
                                <Typography variant="caption" component="small" className={classes.badge}>
                                    +{match.EloMovement}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                    <Box ml="auto" pl={2} display="flex" flexDirection="row">
                        {match.WinnerCharacters.map((c) => (
                            <Box key={c.ID} ml={1}>
                                <img key={c.ID} alt="" src={c.ImageUrl} />
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box alignItems="center" justifyContent="space-between" display="flex" width="100%">
                    <Box display="flex" alignItems="center">
                        <Box mr={2}>{match.LoserRegionShort ? <RegionIcon short={match.LoserRegionShort} /> : <RegionIcon short="AUS" />}</Box>
                        <Box>
                            {match.LoserID ? (
                                <Typography noWrap component={RouterLink} to={RouteList.Player(match.LoserID.toString())}>
                                    {match.LoserName}
                                </Typography>
                            ) : (
                                <Typography noWrap>{match.LoserName}</Typography>
                            )}
                        </Box>
                        <Box>
                            {match.EloMovement && match.EloMovement !== 0 ? (
                                <Typography variant="caption" component="small" className={classes.badgeLoss}>
                                    -{match.EloMovement}
                                </Typography>
                            ) : null}
                        </Box>
                    </Box>
                    <Box ml="auto" pl={2} display="flex" flexDirection="row">
                        {match.LoserCharacters.map((c) => (
                            <Box key={c.ID} ml={1}>
                                <img key={c.ID} alt="" src={c.ImageUrl} />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
