import React, { FC, useContext, useEffect, Fragment, useState } from "react";
import { Page } from "../../components/Page";
import { makeStyles, Theme, createStyles, Box, Paper } from "@material-ui/core";
import { GlobalContext } from "../../contexts/GlobalContext";
import { LocalService } from "../../services/LocalService";
import { useLiveData } from "../../hooks/useLiveData";
import { PlayersService } from "../../services/PlayersService";
import { ApiPocketPlayerWinRate, ApiPlayerWinRateIndexItem } from "../../models/players/ApiPlayerWinRateIndexItem";
import { PlayerHeader } from "../../components/PlayerHeader";
import { MuiVirtualizedTable } from "../../components/ReactVirtualizedTable";
import { PlayerNavigation } from "../../components/PlayerNavigation";
import { RouteList } from "../../Routes";
import { Redirect } from "react-router-dom";
import { RegionButton } from "../../components/RegionButton";
import { LoadingStatus } from "../../components/LoadingStatus";
import { PlayerBreadcrumb } from "../../components/breadcrumbs/PlayerBreadcrumb";

type Props = {
    id: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        heading: {
            display: "flex",
        },
        card: {
            width: "100%",
        },
        cardContent: {
            paddingBottom: 0,
        },
        chip: {
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        list: {
            marginBottom: 0,
            paddingBottom: 0,
        },
        paper: {
            width: "100%",
            height: "100%",
        },
    })
);

export const PlayerWinRatesPage: FC<Props> = (props: Props) => {
    const classes = useStyles();

    const { game, setCurrentNavbarText, setCurrentBottomNavigation, region } = useContext(GlobalContext);

    const [redirect, setRedirect] = useState("");
    const [rates, setRates] = useState<Array<ApiPlayerWinRateIndexItem>>([]);

    const statusKey = game ? LocalService.KeyPlayerWinRates(props.id, game.ID) : "";
    const getLiveData = () => {
        if (game) {
            return PlayersService.getWinRates(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerWinRate>({} as ApiPocketPlayerWinRate);
    };
    const getLocalData = () => {
        if (game) {
            return LocalService.getPlayerWinRates(props.id, game.ID);
        }

        return Promise.resolve<ApiPocketPlayerWinRate>({} as ApiPocketPlayerWinRate);
    };

    const { data, status } = useLiveData<ApiPocketPlayerWinRate | null | undefined>(
        {
            getLiveData: getLiveData,
            getLocalData: getLocalData,
            statusKey: statusKey,
        },
        [game]
    );

    useEffect(() => {
        setCurrentNavbarText("Player");

        if (data) {
            setCurrentBottomNavigation(<PlayerNavigation playerId={data.PlayerID} value={1} />);
        }
    }, [setCurrentNavbarText, data, setCurrentBottomNavigation]);

    useEffect(() => {
        if (data) {
            let newData = data.Items;

            if (region) {
                newData = newData.filter((x) => x.Opponent.RegionShort === region.Short);
            }

            setRates(newData);
        }
    }, [data, region]);

    return (
        <Page >
            {redirect && <Redirect to={redirect} />}

            <Box flexGrow={1} height="100%" display="flex" flexDirection="column">
                {data != null ? (
                    <Fragment>
                        <PlayerBreadcrumb text="Win rates" />

                        <Box justifyContent="space-between" display="flex" mb={2} alignItems="center">
                            <PlayerHeader name={data.PlayerName}  />
                            <Box ml={2}>
                                <RegionButton />
                            </Box>
                        </Box>

                        {data && (
                            <Box maxWidth={500} height="100%">
                                <Paper className={classes.paper}>
                                    <MuiVirtualizedTable
                                        rowCount={rates.length}
                                        rowGetter={({ index }) => rates[index]}
                                        onRowClick={({ rowData }: { rowData: ApiPlayerWinRateIndexItem }) => {
                                            const url = RouteList.Player(rowData.Opponent.ID.toString());

                                            setRedirect(url);
                                        }}
                                        columns={[
                                            {
                                                width: 40,
                                                label: "",
                                                dataKey: `Opponent.RegionShort`,
                                                region: true,
                                            },
                                            {
                                                width: 140,
                                                label: "Player",
                                                dataKey: "Opponent.Name",
                                                link: true,
                                            },
                                            {
                                                width: 60,
                                                label: "W",
                                                dataKey: "Wins",
                                                numeric: true,
                                            },
                                            {
                                                width: 60,
                                                label: "L",
                                                dataKey: "Losses",
                                                numeric: true,
                                            },
                                            {
                                                width: 60,
                                                label: "%",
                                                dataKey: "Percent",
                                                numeric: true,
                                            },
                                        ]}
                                    />
                                </Paper>
                            </Box>
                        )}
                    </Fragment>
                ) : (
                    <LoadingStatus status={status} />
                )}
            </Box>
        </Page>
    );
};
