import { ApiPocketGame } from "../models/games/ApiPocketGame";
import { ApiRegionIndexItem } from "../models/regions/ApiRegionIndexItem";
import { SmashDatabase } from "../db/SmashDatabase";
import { ApiPocketEloItem } from "../models/elo/ApiPocketEloItem";
import { DataStorageState, DefaultDataStorageState } from "../models/local/DataStorageState";
import { nameof } from "../utils/Helpers";
import { ApiPocketCharacter } from "../models/characters/ApiPocketCharacter";
import { TokenKey } from "../contexts/GlobalContext";
import { DataUpdateFrequencyData, DefaultDataUpdateFrequency } from "../hooks/useOfflineData";
import { ApiPocketPlayerStats } from "../models/players/ApiPocketPlayerStats";
import { ApiPocketPlayerWinRate } from "../models/players/ApiPlayerWinRateIndexItem";
import { ApiPocketPlayerResults } from "../models/players/ApiPocketPlayerResults";
import { ApiPocketPlayerVideos } from "../models/players/ApiPocketPlayerVideos";
import { ApiPocketPlayerMatches } from "../models/players/ApiPocketPlayerMatches";
import { ApiPocketResultItem } from "../models/results/ApiPocketResultItem";
import { ApiPocketResultMatches } from "../models/results/ApiPocketResultMatches";
import { ApiPocketResultPlacings } from "../models/results/ApiPocketResultPlacings";

export type StatusType = "Elo";
export type ProfileSetting = "IntroModal";

export class LocalService {
    static KeyGame = "Game";
    static KeyDataUpdateFrequency = "DataUpdateFrequency";
    static KeyEloStatus = (gameId: number) => `Elo${gameId}`;
    static KeyPlayerStats = (playerId: number, gameId: number) => `Player${playerId}Game${gameId}`;
    static KeyPlayerWinRates = (playerId: number, gameId: number) => `Player${playerId}Game${gameId}WinRates`;
    static KeyPlayerResults = (playerId: number, gameId: number) => `Player${playerId}Game${gameId}Results`;
    static KeyPlayerVideos = (playerId: number, gameId: number) => `Player${playerId}Game${gameId}Videos`;
    static KeyPlayerMatches = (playerId: number, gameId: number) => `Player${playerId}Game${gameId}Matches`;
    static KeyResultsStatus = (gameId: number) => `Results${gameId}`;
    static KeyResultMatches = (tourneyId: number, gameId: number) => `Result${tourneyId}Game${gameId}Matches`
    static KeyResultPlacings = (tourneyId: number, gameId: number) => `Result${tourneyId}Game${gameId}Placings`

    static db = new SmashDatabase();

    static isAuthenticated() {
        return true;
        // return localStorage.getItem(TokenKey) != null;
    }

    static setToken(token: string): void {
        localStorage.setItem(TokenKey, token);
    }

    static removeToken(): void {
        localStorage.removeItem(TokenKey);
    }

    static getDataUpdateFrequency(): DataUpdateFrequencyData {
        const d = localStorage.getItem(this.KeyDataUpdateFrequency);

        if (d) {
            var type = JSON.parse(d) as DataUpdateFrequencyData;

            if (type) {
                return type;
            }
        }

        // default data frequency type
        return DefaultDataUpdateFrequency;
    }

    static setDataUpdateFrequency(dataUpdateFrequency: DataUpdateFrequencyData): void {
        localStorage.setItem(this.KeyDataUpdateFrequency, JSON.stringify(dataUpdateFrequency));
    }

    static getGame(): ApiPocketGame | null {
        const game = localStorage.getItem(this.KeyGame);

        if (game) {
            const obj = JSON.parse(game) as ApiPocketGame;

            if (obj) {
                return obj;
            }
        }

        return null;
    }

    static setGame(game: ApiPocketGame | null) {
        if (game) {
            localStorage.setItem(this.KeyGame, JSON.stringify(game));
        } else {
            localStorage.removeItem(this.KeyGame);
        }
    }

    static getRegions(): Promise<Array<ApiRegionIndexItem>> {
        return this.db.regions.toArray();
    }

    static setRegions(regions: Array<ApiRegionIndexItem> | null): void {
        this.db.regions.clear();

        if (regions) {
            this.db.regions.bulkAdd(regions);
        }
    }

    static getCharacters(): Promise<Array<ApiPocketCharacter>> {
        return this.db.characters.orderBy(nameof<ApiPocketCharacter>("Name")).toArray();
    }

    static setCharacters(characters: Array<ApiPocketCharacter> | null): void {
        this.db.characters.clear();

        if (characters) {
            this.db.characters.bulkAdd(characters);
        }
    }

    static getGames(): Promise<Array<ApiPocketGame>> {
        return this.db.games.orderBy(nameof<ApiPocketGame>("SortOrder")).toArray();
    }

    static setGames(games: Array<ApiPocketGame> | null): void {
        this.db.games.clear();

        if (games) {
            this.db.games.bulkAdd(games);
        }
    }

    static getElo(gameId: number): Promise<Array<ApiPocketEloItem>> {
        return this.db.elos.where({ [nameof<ApiPocketEloItem>("GameID")]: gameId }).toArray();
    }

    static getStatus(key: string): Promise<DataStorageState> {
        return this.db.statuses.get(key).then((status) => {
            if (status) {
                return status;
            }

            const defaultState = DefaultDataStorageState(key);

            this.setStatus(defaultState);

            return defaultState;
        });
    }

    static getPlayer(playerId: number, gameId: number): Promise<ApiPocketPlayerStats | null | undefined> {
        return this.db.players.where({ GameID: gameId, ID: +playerId }).first();
    }

    static getPlayerWinRates(playerId: number, gameId: number): Promise<ApiPocketPlayerWinRate | null | undefined> {
        return this.db.playerWinRates.where({ GameID: gameId, PlayerID: +playerId }).first();
    }

    static getPlayerResults(playerId: number, gameId: number): Promise<ApiPocketPlayerResults | null | undefined> {
        return this.db.playerResults.where({ GameID: gameId, PlayerID: +playerId }).first();
    }

    static getPlayerVideos(playerId: number, gameId: number): Promise<ApiPocketPlayerVideos | null | undefined> {
        return this.db.playerVideos.where({ GameID: gameId, PlayerID: +playerId }).first();
    }

    static getPlayerMatches(playerId: number, gameId: number): Promise<ApiPocketPlayerMatches | null | undefined> {
        return this.db.playerMatches.where({ GameID: gameId, PlayerID: +playerId }).first();
    }

    static getResults(gameId: number): Promise<Array<ApiPocketResultItem>> {
        return this.db.results.where({ [nameof<ApiPocketResultItem>("GameID")]: gameId }).toArray();
    }

    static getResultMatches(tourneyId: number, gameId: number): Promise<ApiPocketResultMatches | null | undefined> {
        return this.db.resultMatches.where({ GameID: gameId, TourneyID: +tourneyId }).first();
    }

    static getResultPlacings(tourneyId: number, gameId: number): Promise<ApiPocketResultPlacings | null | undefined> {
        return this.db.resultPlacings.where({ GameID: gameId, TourneyID: +tourneyId }).first();
    }

    static setStatus(status: DataStorageState) {
        this.db.statuses.put(status);
    }

    static getEloStatus(gameId: number): Promise<DataStorageState> {
        const key = this.KeyEloStatus(gameId);
        return this.getStatus(key);
    }

    static updateEloStatusLastUpdated(gameId: number) {
        this.getEloStatus(gameId).then((x) => {
            const newStatus: DataStorageState = { ...x, LastUpdated: new Date() };
            this.setStatus(newStatus);
        });
    }

    static getProfileSetting(setting: ProfileSetting) {
        const d = localStorage.getItem(setting);

        if (d) {
            return Boolean(d);
        }

        return false;
    }

    static setProfileSetting(setting: ProfileSetting, value: boolean) {
        localStorage.setItem(setting, String(value));
    }
}
