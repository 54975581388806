import React, { FC } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { RouteList } from "../../Routes";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";

type Props = {
    text: string
}

export const PlayerBreadcrumb: FC<Props> = (props: Props) => {
    return (
        <Box mb={1}>
            <Breadcrumbs>
                <RouterLink to={RouteList.Elo}>Elo</RouterLink>
                <Typography>Player</Typography>
                <Typography>{props.text}</Typography>
            </Breadcrumbs>
        </Box>
    );
};
