import { BaseApiService } from "./BaseApiService";
import { ApiPocketResultItem } from "../models/results/ApiPocketResultItem";
import { ApiPocketResultMatches } from "../models/results/ApiPocketResultMatches";
import { ApiPocketResultPlacings } from "../models/results/ApiPocketResultPlacings";

export class ResultsService extends BaseApiService {
    static getGame(id: number): Promise<Array<ApiPocketResultItem>> {
        return this.get<Array<ApiPocketResultItem>>(`/pocket/results/${id}`).then((x) => {
            this.db.results.bulkPut(x);
            return x;
        });
    }

    static getMatches(playerId: number, gameId: number): Promise<ApiPocketResultMatches> {
        return this.get<ApiPocketResultMatches>(`/pocket/result/matches/${playerId}/${gameId}`).then((x) => {
            this.db.resultMatches.put(x);
            return x;
        })
    }

    static getPlacings(playerId: number, gameId: number): Promise<ApiPocketResultPlacings> {
        return this.get<ApiPocketResultPlacings>(`/pocket/result/placings/${playerId}/${gameId}`).then((x) => {
            this.db.resultPlacings.put(x);
            return x;
        })
    }
}