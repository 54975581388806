import React, { FC, useContext, useState, Fragment } from "react";
import { makeStyles, createStyles, Button, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import { ApiRegionIndexItem } from "../models/regions/ApiRegionIndexItem";
import { RegionIcon } from "./RegionIcon";
import { GlobalContext } from "../contexts/GlobalContext";

const useStyles = makeStyles(() =>
    createStyles({
        btn: {
            height: 40,
        },
        btnImg: {
            maxWidth: 16,
        },
        paper: {
            width: "100%",
            height: "100%",
        },
    })
);

export const RegionButton: FC = () => {
    const classes = useStyles();

    const { regions, region, setCurrentRegion } = useContext(GlobalContext);

    const [regionEl, setRegionEl] = useState<null | HTMLElement>(null);

    const handleRegionMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setRegionEl(event.currentTarget);
    };

    const handleRegionMenuClosed = () => {
        setRegionEl(null);
    };

    const handleRegionMenuItemClick = (region: ApiRegionIndexItem | null) => {
        setCurrentRegion(region);
        handleRegionMenuClosed();
    };

    return (
        <Fragment>
            <Button className={classes.btn} variant="outlined" onClick={handleRegionMenuClick}>
                <RegionIcon short={region?.Short ?? "AUS"} />
            </Button>

            <Menu anchorEl={regionEl} open={Boolean(regionEl)} keepMounted onClose={handleRegionMenuClosed}>
                <MenuItem onClick={() => handleRegionMenuItemClick(null)}>
                    <ListItemIcon>
                        <RegionIcon short="AUS" />
                    </ListItemIcon>
                    Australia
                </MenuItem>
                {regions.map((r) => (
                    <MenuItem onClick={() => handleRegionMenuItemClick(r)} key={r.ID}>
                        <ListItemIcon>
                            <RegionIcon short={r.Short} />
                        </ListItemIcon>
                        {r.Name}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    );
};
