import { useState, Dispatch, SetStateAction, useCallback } from "react";

export interface IOfflineData {
    loading: boolean;
    loadedLocally: boolean;
    success: boolean | null;
    lastUpdated: Date | null;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setLoadedLocally: Dispatch<SetStateAction<boolean>>;
    setSuccess: Dispatch<SetStateAction<boolean | null>>;
    setLastUpdated: Dispatch<SetStateAction<Date | null>>;
    reset: () => void;
}

const defaultLoading = true;
const defaultLoadedLocally = false;
const defaultSuccess = null;
const defaultLastUpdated = null;

export const useOfflineData = (): IOfflineData => {
    const [loading, setLoading] = useState(defaultLoading);
    const [loadedLocally, setLoadedLocally] = useState(defaultLoadedLocally);
    const [success, setSuccess] = useState<boolean | null>(defaultSuccess);
    const [lastUpdated, setLastUpdated] = useState<Date | null>(defaultLastUpdated);

    const reset = useCallback(() => {
        setLoading(defaultLoading);
        setLoadedLocally(defaultLoadedLocally);
        setSuccess(defaultSuccess);
        setLastUpdated(defaultLastUpdated);
    }, []);

    return {
        loading,
        setLoading,
        loadedLocally,
        setLoadedLocally,
        success,
        setSuccess,
        lastUpdated,
        setLastUpdated,
        reset,
    };
};

export type DataUpdateFrequencyType = "EveryLoad" | "After1Hour" | "After1Day" | "EveryMinute";
export type DataUpdateFrequencyData = { key: string; value: number };

export const DataUpdateFrequency: Record<DataUpdateFrequencyType, DataUpdateFrequencyData> = {
    EveryLoad: {
        key: "Every load",
        value: 0,
    },
    EveryMinute: {
        key: "Every minute",
        value: 60,
    },
    After1Hour: {
        key: "After 1 hour",
        value: 3600,
    },
    After1Day: {
        key: "After 1 day",
        value: 86400,
    },
};

export const DefaultDataUpdateFrequency = DataUpdateFrequency.After1Day;